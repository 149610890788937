import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { SlotService } from "../service/slot.service";
import { Companylist } from "./companylist";
import { I18nService } from "../service/i18n.service";

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
  game = "game",
  slot = "slot",
}

interface Props {
  user: any;
  activeHelp: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any, lang: string) => any;
  tryLoginOut: () => any;
  lang: string;
}

interface State {
  mode: string;
  slots: any;
  games: any;
  withdraws: any;
  deposits: any;
  liveCasinos: any;
  hotelCasinos: any;
  gameCode: any;
  gameName: any;
  isOpen: boolean;
  popupStatuses: string;
  lang: string;
}

export class Contents extends Component<Props, State> {
  slotService = new SlotService();
  i18nService = new I18nService();

  constructor(props: any) {
    super(props);
    this.state = {
      mode: Mode.none,
      slots: [],
      games: [],
      withdraws: [],
      deposits: [],
      liveCasinos: [],
      hotelCasinos: [],
    
      gameCode: "",
      gameName: "",
      isOpen: false,
      popupStatuses: popupView.none,
      lang: this.props.lang,
    };
  }

  componentDidMount() {
    this.slotService.getSlotSetting().then((s: any) => {
      if (s.status === "success") {
        this.setState({ slots: s.slot, mode: Mode.slot });
      }
    });

    this.slotService.getCasinoSetting().then((s) => {
      if (s.status === "success") {


        const liveCasinos =  []
        const hotelCasinos =  []
        for(const game of s.casiono){
          if(game.isHotel === 'y'){
            hotelCasinos.push(game)
          }else{
            liveCasinos.push(game)
          }
        }
  
        this.setState({ liveCasinos: liveCasinos, hotelCasinos: hotelCasinos, });
      }
    });
  }

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });
      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  handleGame = (name: string) => {
    this.slotService.get_slot_by_company(name).then((s: any) => {
      if (s.status === "success") {
        this.setState({ games: s.games, mode: Mode.game });
      }
    });
  };

  RenderSlot = (info: any) => {
    const imgUrl = info.mobileImg;
    return (
      <>
        <div className="provider-item">
          <h5 className="casino-name">{info.nameKo}</h5>
          <div className="game-5-CAGAYAN&nbsp;88 card-provider glow-effect gold">
            <span className="bg"></span>
            <span className="shine"></span>

            <div className="provider-logo">
              <img src={imgUrl} className="img-responsive" />
            </div>
            <div className="card-action">
              <button
                className="btn btn-card"
                key={`key${info.nameEn}kkk`}
                onClick={() => {
                  if (info.used === "y") {
                    this.setState({ gameCode: info.code });
                    this.setState({ gameName: info.nameKo });
                    this.setState({ popupStatuses: popupView.game });
                  } else {
                    confirmAlert({
                      title: "점검중",
                      message: "현재 해당게임은 점검중입니다 .",
                      buttons: [
                        {
                          label: "확인",
                          onClick: () => {},
                        },
                      ],
                    });
                  }
                }}
              >
                <span className="btn-arrow"></span>
                {/* 게임입장하기 */}
                {this.i18nService.getMainMessage(this.state.lang, "entergame")}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  RenderCasino = (info: any) => {
    const imgUrl = info.mobileImg;
    return (
      <>
        <div className="provider-item">
          <h5 className="casino-name">{info.nameKo}</h5>
          <div className="game-5-CAGAYAN&nbsp;88 card-provider glow-effect gold">
            <span className="bg"></span>
            <span className="shine"></span>

            <div className="provider-logo">
              <img src={imgUrl} className="img-responsive" />
            </div>
            <div className="card-action">
              <button
                className="btn btn-card"
                key={`key${info.nameEn}kkk`}
                onClick={() => {
                  if (info.used === "y") {
                    this.handleOpenSlot(info.code, info.gameCompany);
                  } else {
                    confirmAlert({
                      title: "점검중",
                      message: "현재 해당게임은 점검중입니다 .",
                      buttons: [
                        {
                          label: "확인",
                          onClick: () => {},
                        },
                      ],
                    });
                  }
                }}
              >
                <span className="btn-arrow"></span>
                {/* 게임입장하기 */}
                {this.i18nService.getMainMessage(this.state.lang, "entergame")}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  RenderGame = (info: any) => {
    const imgUrl = info.mobileImg;
    return (
      <>
        <div className="provider-item">
          <h5 className="casino-name">{info.nameKo}</h5>
          <div className="game-5-CAGAYAN&nbsp;88 card-provider glow-effect gold">
            <span className="bg"></span>
            <span className="shine"></span>

            <div className="provider-logo">
              <img src={imgUrl} className="img-responsive" />
            </div>
            <div className="card-action">
              <button
                className="btn btn-card"
                key={`key${info.nameEn}kkk`}
                onClick={() => {
                  if (info.used === "y") {
                    this.handleOpenSlot(info.code, info.gameCompany);
                  } else {
                    confirmAlert({
                      title: "점검중",
                      message: "현재 해당게임은 점검중입니다 .",
                      buttons: [
                        {
                          label: "확인",
                          onClick: () => {},
                        },
                      ],
                    });
                  }
                }}
              >
                <span className="btn-arrow"></span>
                {/* 게임입장하기 */}
                {this.i18nService.getMainMessage(this.state.lang, "entergame")}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    const authenticated = this.props.authenticated;

    const RenderPopup = () => {
      if (this.state.popupStatuses === popupView.game) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Companylist
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            gameCode={this.state.gameCode}
            gameName={this.state.gameName}
            lang={this.state.lang}
          ></Companylist>
        );
      }

      return <div></div>;
    };

    const RenderCompany = () => {
      if (this.state.mode === Mode.game) {
        return <>{this.state.games.map((row: any) => this.RenderGame(row))}</>;
      }

      return (
        <>
        </>
      );
    };

    return (
      <>
        <div style={{
              color: '#ffffff',
              fontFamily: "'Raleway',sans-serif",
              fontSize: '62px',
              fontWeight: 800,
              lineHeight: '72px',
              textAlign: "center",
              textTransform: "uppercase"
        }}
        >호텔 카지노</div>

        <div className="provider-list">
          {this.state.hotelCasinos.map((row: any) => this.RenderCasino(row))}
        </div>

        <div style={{
              color: '#ffffff',
              fontFamily: "'Raleway',sans-serif",
              fontSize: '62px',
              fontWeight: 800,
              lineHeight: '72px',
              textAlign: "center",
              textTransform: "uppercase"
        }}
        >라이브 카지노</div>

        <div className="provider-list">
          {this.state.liveCasinos.map((row: any) => this.RenderCasino(row))}
        </div>
        {RenderPopup()}
      </>
    );
  }
}
