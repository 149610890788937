import React, { Component } from "react";
import Popup from "reactjs-popup";
import { SlotService } from "../service/slot.service";
import { I18nService } from "../service/i18n.service";

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
}

interface Props {
  gameCode: string;
  gameName: string;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
  lang: string;
}

interface State {
  mode: string;
  slots: any;
  games: any;
  withdraws: any;
  deposits: any;
  casionos: any;
  isOpen: boolean;
  lang: string;
}

export class Companylist extends Component<Props, State> {
  slotService = new SlotService();
  i18nService = new I18nService();

  constructor(props: Props) {
    super(props);

    this.state = {
      mode: Mode.none,
      slots: [],
      games: [],
      withdraws: [],
      deposits: [],
      casionos: [],
      isOpen: false,
      lang: this.props.lang,
    };
  }

  componentDidMount() {
    this.slotService.get_slot_by_company(this.props.gameCode).then((s: any) => {
      if (s.status === "success") {
        this.setState({ games: s.games, mode: Mode.game });
      }
    });
  }

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });

      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  RenderGame = (info: any) => {
    return (
      <>
        <div className="col-xs-12 col-sm-4 col-md-4">
          <ul id="tabs" className="list-inline">
            <li style={{ paddingLeft: "0" }}>
              <a href="javascript:;" className="game-table-selected-5-50">
                {info.nameKo}
              </a>
            </li>
          </ul>
          <div className="panel panel-default">
            <div
              className="panel-body p-0 m-b-10 m-l-10 m-t-0"
              style={{ height: "170px", overflow: "hidden" }}
            >
              <img
                src={`${info.imgUrl}`}
                data-src={`${info.imgUrl}`}
                className="img-responsive"
                style={{ width: "150px", height: "150px", margin: "auto" }}
              />
            </div>
          </div>

          <div className="overlay-button">
            <a href="javascript:;" className="game-table-selected-7-88">
              <button
                className="game-table-enter btn btn-default bg-metallic"
                onClick={() => {
                  this.handleOpenSlot(info.code, info.gameCompany);
                }}
              >
                {/* 입장 */}
                {this.i18nService.getMainMessage(this.state.lang, "entergame2")}
              </button>
            </a>
          </div>
        </div>
      </>
    );
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              id="table-selection-modal"
              className="modal modal-theme fade in"
              role="dialog"
              style={{ display: "block" }}
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header text-center">
                    <button
                      className="btn-close"
                      style={{ right: "15px" }}
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    ></button>
                    <h4 className="modal-title" id="game-provider-title">
                      <span>ORIENTAL&nbsp;GAME</span>
                    </h4>
                  </div>
                  <div className="modal-body" style={{ padding: "15px 0" }}>
                    <div
                      className="slimScrollDiv"
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        width: "auto",
                        height: "480px",
                      }}
                    >
                      <div
                        id="table-section"
                        style={{
                          overflowX: "hidden",
                          overflowY: "scroll",
                          width: "auto",
                          height: "480px",
                        }}
                      >
                        <div id="standard-table" className="row">
                          {this.state.games.map((row: any) =>
                            this.RenderGame(row)
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
