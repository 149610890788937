import React, { Component } from "react";
import styled from "styled-components";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";
import { I18nService } from "../service/i18n.service";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
}

const CustomTableCell = styled(TableCell)`
  background: linear-gradient(#2c2c2c 0%, #1c1c1c 100%);
  color: #dddddd;
  line-height: 44px;
  text-align: center;
  font-weight: bold;
  font-family: "nanumgothic", sans-serif;
`;

const CustomTableCell2 = styled(TableCell)`
  background: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
  line-height: 40px;
  padding: 0 0 0 15px;
  font-weight: bold;
`;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  let ref = "";
  if (row.ref != null) {
    ref = row.ref.contents;
  }

  return (
    <React.Fragment>
      <TableRow className={classes.root} key={row._id}>
        <CustomTableCell2>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CustomTableCell2>
        <CustomTableCell2 align="center" onClick={() => setOpen(!open)}>
          {row.title}
        </CustomTableCell2>
        <CustomTableCell2 align="center">
          {ConvertDate(row.row)}
        </CustomTableCell2>
        <CustomTableCell2 align="center"></CustomTableCell2>
      </TableRow>
      <TableRow>
        <CustomTableCell2
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div
                style={{
                  height: "400px",
                  overflowY: "scroll",
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: row.contents }}></div>
              </div>
            </Box>
          </Collapse>
        </CustomTableCell2>
      </TableRow>
    </React.Fragment>
  );
}

interface Props {
  handleClose: () => any;
  handleChange: (viewName: string) => any;
  lang: string;
}

interface State {
  notices: any;
  lang: string;
}

export class Notice extends Component<Props, State> {
  userService = new UserService();
  i18nService = new I18nService();

  constructor(props: Props) {
    super(props);
    this.state = { notices: [], lang: this.props.lang };
  }

  componentDidMount() {
    this.handleGetNotices();
  }

  handleGetNotices = () => {
    this.userService.get_user_notices().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({ notices: data.notices });
      }
    });
  };

  render() {
    let notices = this.state.notices;
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              id="cashin-modal"
              className="modal modal-theme fade in"
              role="dialog"
              style={{ display: "block", paddingRight: "17px" }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">
                      {/* 공지사항 */}
                      {this.i18nService.getBoardMessage(
                        this.state.lang,
                        "notice"
                      )}
                    </h4>
                    <button
                      className="btn-close"
                      data-dismiss="modal"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <TableContainer component={Paper}>
                      <Table
                        size="small"
                        aria-label="a dense table"
                        style={{ marginBottom: "0px" }}
                        className="table table-bordered table-striped"
                      >
                        <TableHead>
                          <TableRow>
                            <CustomTableCell align="center"></CustomTableCell>
                            <CustomTableCell align="center">
                              {/* 제 목 */}
                              {this.i18nService.getBoardMessage(
                                this.state.lang,
                                "title"
                              )}
                            </CustomTableCell>
                            <CustomTableCell align="center">
                              {/* 작성일 */}
                              {this.i18nService.getBoardMessage(
                                this.state.lang,
                                "date"
                              )}
                            </CustomTableCell>
                            <CustomTableCell align="center">
                              {/* 상태 */}
                              {this.i18nService.getBoardMessage(
                                this.state.lang,
                                "status"
                              )}
                            </CustomTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {notices.map((row: any) => (
                            <Row key={row.name} row={row} />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <div className="modal-footer"></div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
