import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { MiniService } from "../service/mini.service";
import { Header } from "./header";
import { Contents } from "./contents";
import { Footer } from "./footer";
import { I18nService } from "../service/i18n.service";

interface Props {
  authenticated: boolean;
  activeHelp: boolean;
  session: any;
  user: any;
  tryLogin: (id: any, pw: any, lang: string) => any;
  tryLoginOut: () => any;
  updateUser: () => any;
  lang: string;
}

interface State {}

export class Main extends Component<Props, State> {
  divElement: any;
  miniService = new MiniService();
  i18nService = new I18nService();

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.divElement != null) {
      this.setState({});
    }
  }

  createData = (
    name: any,
    balnce: any,
    fat: any,
    maxBalanceCount: any,
    carbs: any,
    protein: any
  ) => {
    return {
      name,
      balnce,
      fat,
      maxBalanceCount,
      buyCount: 0,
      sellCount: 0,
      carbs,
      protein,
    };
  };

  updateUserDate = () => {};

  updateMiniHistroy = (page: any) => {
    this.miniService.getMiniGameBetList(page).then((s: any) => {
      if (s.status === "success") {
        this.setState({ history: s.minis });
      }
    });
  };

  updateNowDate = (currency: string, minute: number) => {};

  updateGameDate = (currency: string, minute: number) => {};

  handleDobet = (episode: string) => {};

  render() {
    return (
      <>
        <div className="bg-video">
          <video autoPlay loop>
            <source src="/images/bg4.mp4" type="video/mp4" />
          </video>
        </div>

        <Header
          activeHelp={this.props.activeHelp}
          tryLogin={this.props.tryLogin}
          tryLoginOut={this.props.tryLoginOut}
          user={this.props.user}
          authenticated={this.props.authenticated}
          updateUser={this.props.updateUser}
          lang={this.props.lang}
        ></Header>

        <Contents
          activeHelp={this.props.activeHelp}
          tryLogin={this.props.tryLogin}
          tryLoginOut={this.props.tryLoginOut}
          user={this.props.user}
          authenticated={this.props.authenticated}
          lang={this.props.lang}
        ></Contents>

        <Footer></Footer>
      </>
    );
  }
}
