import React, { Component } from "react";

interface Props {}
interface State {}

export class Footer extends Component<Props, State> {
  render() {
    return (
      <>
        <div className="col-lg-12 padding-0 footer-bg">
          <div className="container-fluid">
            <div className="row">
              <p className="footer text-center">
                COPYRIGHT (C) 2018 블루칩 CASINO, LTD ALL RIGHTS RESERVED
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}
