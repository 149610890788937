import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { UserService } from "../service/user.service";
import { ConverMoeny } from "../utility/help";
import { BalanceService } from "../service/balance.service";
import { I18nService } from "../service/i18n.service";
import { SlotService } from "../service/slot.service";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
}

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
  lang: string;
}

interface State {
  pass1: string;
  pass2: string;
  balance: string;
  point: string;
  lang: string;
}

export class User extends Component<Props, State> {
  userService = new UserService();
  balanceService = new BalanceService();
  slotService = new SlotService();
  i18nService = new I18nService();

  constructor(props: Props) {
    super(props);

    this.state = {
      pass1: "",
      pass2: "",
      balance: "",
      point: "",
      lang: this.props.lang,
    };

    console.log(this.props.user);
  }

  
  componentDidMount() {
    this.handleUpdateInBalance();
  }


  handleUpdateInBalance = () => {
    this.slotService.get_in_game_balance().then((data: any) => {
      if (data.status === "success") {
        this.setState({
          balance: data.balance ?? 0,
        });
        this.props.user.balance = data.balance ?? 0;
      } else {
      }
    });
  };


  handleChangeToBalance = () => {
    if (Number(this.state.balance === "" ? 0 : this.state.balance) <= 0) {
      confirmAlert({
        title: this.i18nService.getMypageMessage(this.state.lang, "mypage"), //"벨런스",
        message: this.i18nService.getMypageMessage(this.state.lang, "message1"), //"벨런스를 입력해주세요.",
        buttons: [
          {
            label: this.i18nService.getMypageMessage(
              this.state.lang,
              "confirm"
            ), //"확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    confirmAlert({
      title: this.i18nService.getMypageMessage(this.state.lang, "mypage"), //"벨런스",
      message: this.i18nService.getMypageMessage(this.state.lang, "message2"), //"보유머니를 입금 하시겠습니까?",
      buttons: [
        {
          label: this.i18nService.getMypageMessage(this.state.lang, "confirm"), //"확인",
          onClick: () => {
            this.balanceService
              .user_balance_to_point(
                Number(this.state.balance === "" ? 0 : this.state.balance)
              )
              .then((data: any) => {
                if (data.status === "success") {
                  confirmAlert({
                    title: this.i18nService.getMypageMessage(
                      this.state.lang,
                      "mypage"
                    ), //"벨런스",
                    message: this.i18nService.getMypageMessage(
                      this.state.lang,
                      "message3"
                    ), //"벨런스를 금고머니로 변경하였습니다.",
                    buttons: [
                      {
                        label: this.i18nService.getMypageMessage(
                          this.state.lang,
                          "confirm"
                        ), //"확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
              });
          },
        },

        {
          label: this.i18nService.getMypageMessage(this.state.lang, "cancel"), //"취소",
          onClick: () => {},
        },
      ],
    });
  };

  handleChangeToPoint = () => {
    if (Number(this.state.point === "" ? 0 : this.state.point) <= 0) {
      confirmAlert({
        title: this.i18nService.getMypageMessage(this.state.lang, "mypage"), //"금고머니",
        message: this.i18nService.getMypageMessage(this.state.lang, "message4"), //"금고머니를 입력해주세요.",
        buttons: [
          {
            label: this.i18nService.getMypageMessage(
              this.state.lang,
              "confirm"
            ), //"확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    confirmAlert({
      title: this.i18nService.getMypageMessage(this.state.lang, "mypage"), //"금고머니",
      message: this.i18nService.getMypageMessage(this.state.lang, "message5"), //"금고머니를 출금하시겠습니까?",
      buttons: [
        {
          label: this.i18nService.getMypageMessage(this.state.lang, "confirm"), //"확인",
          onClick: () => {
            this.balanceService
              .user_point_to_money(
                Number(this.state.point === "" ? 0 : this.state.point)
              )
              .then((data: any) => {
                if (data.status === "success") {
                  confirmAlert({
                    title: this.i18nService.getMypageMessage(
                      this.state.lang,
                      "mypage"
                    ), //"금고머니",
                    message: this.i18nService.getMypageMessage(
                      this.state.lang,
                      "message6"
                    ), //"금고머니를 벨런스로 변경하였습니다.",
                    buttons: [
                      {
                        label: this.i18nService.getMypageMessage(
                          this.state.lang,
                          "confirm"
                        ), //"확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
              });
          },
        },

        {
          label: this.i18nService.getMypageMessage(this.state.lang, "cancel"), //"취소",
          onClick: () => {},
        },
      ],
    });
  };

  handleExchangeToPassword = () => {
    if (this.state.pass1.length < 3 || this.state.pass1.length > 20) {
      confirmAlert({
        title: this.i18nService.getMypageMessage(this.state.lang, "mypage"), //"회원정보",
        message: this.i18nService.getMypageMessage(this.state.lang, "message7"), //"비밀번호 입력 또는 3자리 이상 20자리 이하로 작성해주세요",
        buttons: [
          {
            label: this.i18nService.getMypageMessage(
              this.state.lang,
              "confirm"
            ), //"확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (this.state.pass1 !== this.state.pass2) {
      confirmAlert({
        title: this.i18nService.getMypageMessage(this.state.lang, "mypage"), //"회원정보",
        message: this.i18nService.getMypageMessage(this.state.lang, "message8"), //"비밀번호정보가 동일해야합니다.",
        buttons: [
          {
            label: this.i18nService.getMypageMessage(
              this.state.lang,
              "confirm"
            ), //"확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.userService.user_exchange_to_pass(this.state.pass1).then((s: any) => {
      if (s.status === "success") {
        confirmAlert({
          title: this.i18nService.getMypageMessage(this.state.lang, "mypage"), //"회원정보",
          message: this.i18nService.getMypageMessage(
            this.state.lang,
            "message9"
          ), //"비밀번호정보가 정상적으로 변경되었습니다. ",
          buttons: [
            {
              label: this.i18nService.getMypageMessage(
                this.state.lang,
                "confirm"
              ), //"확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: this.i18nService.getMypageMessage(this.state.lang, "mypage"), //"회원정보",
          message: this.i18nService.getMypageMessage(
            this.state.lang,
            "message10"
          ), //"알수없는 예러가 발생되습니다 지속적인 문제가 발생되면 문의 부탁드림니다. ",
          buttons: [
            {
              label: this.i18nService.getMypageMessage(
                this.state.lang,
                "confirm"
              ), //"확인",
              onClick: () => {},
            },
          ],
        });
        return;
      }
    });
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              id="cashin-modal"
              className="modal modal-theme fade in"
              role="dialog"
              style={{ display: "block", paddingRight: "17px" }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">
                      {/* 마이페이지 */}
                      {this.i18nService.getMypageMessage(
                        this.state.lang,
                        "mypage"
                      )}
                    </h4>
                    <button
                      className="btn-close"
                      data-dismiss="modal"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <ul className="nav nav-tabs">
                      <li className="active">
                        <a data-toggle="tab" href="#all" aria-expanded="false">
                          {/* 전체 */}
                          {this.i18nService.getMypageMessage(
                            this.state.lang,
                            "all"
                          )}
                        </a>
                      </li>
                      <li className="">
                        <a data-toggle="tab" href="#pass" aria-expanded="true">
                          {/* 비밀번호 변경 */}
                          {this.i18nService.getMypageMessage(
                            this.state.lang,
                            "changepassword"
                          )}
                        </a>
                      </li>
                      <li className="">
                        <a
                          data-toggle="tab"
                          href="#credits-tab"
                          aria-expanded="false"
                        >
                          {/* 금고 */}
                          {this.i18nService.getMypageMessage(
                            this.state.lang,
                            "pointmanagement"
                          )}
                        </a>
                      </li>
                    </ul>

                    <div className="tab-content">
                      <div id="all" className="tab-pane fade active in">
                        <div className="form-group">
                          <label>
                            {/* 아이디 */}
                            {this.i18nService.getMypageMessage(
                              this.state.lang,
                              "id"
                            )}
                          </label>
                          <p id="profile-username" className="form-details">
                            {this.props.user.id}
                          </p>
                        </div>
                        <div className="form-group m-t-30">
                          <label>
                            {/* 보유금액 */}
                            {this.i18nService.getMypageMessage(
                              this.state.lang,
                              "holdamount"
                            )}
                          </label>
                          <p className="form-details text-yellow profile-credits">
                            {this.props.user.balance} ₩
                          </p>
                        </div>
                        <div className="form-group m-t-30">
                          <label>
                            {/* 금고금액 */}
                            {this.i18nService.getMypageMessage(
                              this.state.lang,
                              "money"
                            )}
                          </label>
                          <p className="form-details text-yellow profile-credits">
                            {this.props.user.point} ₩
                          </p>
                        </div>
                        <div className="form-group">
                          <label>
                            {/* 예금주명 */}
                            {this.i18nService.getMypageMessage(
                              this.state.lang,
                              "accountholder"
                            )}
                          </label>
                          <p id="profile-nickname" className="form-details">
                            {this.props.user.bankowner}
                          </p>
                        </div>
                        <div className="form-group">
                          <label>
                            {/* 회원은행 */}
                            {this.i18nService.getMypageMessage(
                              this.state.lang,
                              "bank"
                            )}
                          </label>
                          <p id="profile-nickname" className="form-details">
                            {this.props.user.bankname}
                          </p>
                        </div>
                        <div className="form-group">
                          <label>
                            {/* 출금 계좌번호 */}
                            {this.i18nService.getMypageMessage(
                              this.state.lang,
                              "account"
                            )}
                          </label>
                          <p id="profile-nickname" className="form-details">
                            {this.props.user.banknum}
                          </p>
                        </div>
                      </div>
                      <div id="pass" className="tab-pane fade">
                        <div className="form-group">
                          <label>
                            {/* 신규비밀번호 */}
                            {this.i18nService.getMypageMessage(
                              this.state.lang,
                              "newpassword"
                            )}
                          </label>
                          <input
                            className="form-control"
                            type="password"
                            name="pass"
                            maxLength={15}
                            placeholder={this.i18nService.getMypageMessage(
                              this.state.lang,
                              "password1"
                            )} //"새 비밀번호를 입력해주세요"
                            value={this.state.pass1}
                            onChange={(e: any) => {
                              this.setState({ pass1: e.target.value });
                            }}
                          />
                          <span className="help-block"></span>
                        </div>
                        <div className="form-group">
                          <label>
                            {/* 비밀번호확인 */}
                            {this.i18nService.getMypageMessage(
                              this.state.lang,
                              "confirmpassword"
                            )}
                          </label>
                          <input
                            className="form-control"
                            type="password"
                            name="pass2"
                            maxLength={15}
                            placeholder={this.i18nService.getMypageMessage(
                              this.state.lang,
                              "password2"
                            )} //"새 비밀번호를 다시 입력해주세요"
                            value={this.state.pass2}
                            onChange={(e: any) => {
                              this.setState({ pass2: e.target.value });
                            }}
                          />
                          <span className="help-block"></span>
                        </div>
                        <div className="form-group">
                          <span className="error" style={{ display: "none" }}>
                            {/* 위에 입력하신 비밀번호와 일치하지 않습니다. */}
                            {this.i18nService.getMypageMessage(
                              this.state.lang,
                              "passwordresult1"
                            )}
                          </span>
                          <span className="granted" style={{ display: "none" }}>
                            {/* 위에 입력하신 비밀번호와 일치합니다. */}
                            {this.i18nService.getMypageMessage(
                              this.state.lang,
                              "passwordresult2"
                            )}
                          </span>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            id="btn-update-password"
                            className="btn bg-metallic"
                            onClick={this.handleExchangeToPassword}
                          >
                            {/* 업데이트 */}
                            {this.i18nService.getMypageMessage(
                              this.state.lang,
                              "update"
                            )}
                          </button>
                        </div>
                      </div>
                      <div id="credits-tab" className="tab-pane fade">
                        <div className="form-group">
                          <label>
                            {/* 보유금액 */}
                            {this.i18nService.getMypageMessage(
                              this.state.lang,
                              "holdamount"
                            )}
                          </label>
                          <p
                            className="text-yellow form-details"
                            id="current_balance"
                          >
                            {ConverMoeny(this.props.user.balance)} ₩
                          </p>
                        </div>
                        <div className="form-group">
                          <label>
                            {/* 금고입금 */}
                            {this.i18nService.getMypageMessage(
                              this.state.lang,
                              "safe1"
                            )}
                          </label>
                          <input
                            className="form-control"
                            size={30}
                            value={this.state.balance}
                            onClick={(e) =>
                              this.setState({
                                balance: "",
                              })
                            }
                            onChange={(e) =>
                              this.setState({
                                balance: e.target.value,
                              })
                            }
                            style={{ maxWidth: "40%" }}
                          />
                          <button
                            type="button"
                            id="btn-cashout-proceed"
                            className="btn btn-theme-blue"
                            onClick={this.handleChangeToBalance}
                            style={{ flexBasis: "33.333%", marginLeft: "auto" }}
                          >
                            {/* 입금하기 */}
                            {this.i18nService.getMypageMessage(
                              this.state.lang,
                              "depositmoney"
                            )}
                          </button>
                        </div>
                        <div className="form-group">
                          <label>
                            {/* 금고출금 */}
                            {this.i18nService.getMypageMessage(
                              this.state.lang,
                              "safe2"
                            )}
                          </label>
                          <input
                            className="form-control"
                            size={30}
                            value={this.state.point}
                            onClick={(e) =>
                              this.setState({
                                point: "",
                              })
                            }
                            onChange={(e) =>
                              this.setState({
                                point: e.target.value,
                              })
                            }
                            style={{ maxWidth: "40%" }}
                          />
                          <button
                            type="button"
                            id="btn-cashout-proceed"
                            className="btn btn-theme-blue"
                            onClick={this.handleChangeToPoint}
                            style={{ flexBasis: "33.333%", marginLeft: "auto" }}
                          >
                            {/* 출금하기 */}
                            {this.i18nService.getMypageMessage(
                              this.state.lang,
                              "withdrawmoney"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer"></div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
