export class I18nService {
  getMessage = (lang: any, message: any) => {
    let returnMsg = "";

    if (message === "username") {
      if (lang === "ko") {
        returnMsg = "아이디";
      } else if (lang === "en") {
        returnMsg = "Username";
      } else if (lang === "ch") {
        returnMsg = "用户名";
      } else if (lang === "ja") {
        returnMsg = "ユーザー名";
      }
    } else if (message === "password") {
      if (lang === "ko") {
        returnMsg = "비밀번호";
      } else if (lang === "en") {
        returnMsg = "Password";
      } else if (lang === "ch") {
        returnMsg = "密码";
      } else if (lang === "ja") {
        returnMsg = "パスワード";
      }
    } else if (message === "login") {
      if (lang === "ko") {
        returnMsg = "로그인";
      } else if (lang === "en") {
        returnMsg = "Login";
      } else if (lang === "ch") {
        returnMsg = "登录";
      } else if (lang === "ja") {
        returnMsg = "ログイン";
      }
    } else if (message === "signin") {
      if (lang === "ko") {
        returnMsg = "로그인을 하세요";
      } else if (lang === "en") {
        returnMsg = "Sign In";
      } else if (lang === "ch") {
        returnMsg = "签到";
      } else if (lang === "ja") {
        returnMsg = "サインイン";
      }
    } else if (message === "signup") {
      if (lang === "ko") {
        returnMsg = "회원가입";
      } else if (lang === "en") {
        returnMsg = "Sign Up";
      } else if (lang === "ch") {
        returnMsg = "寄存器";
      } else if (lang === "ja") {
        returnMsg = "サインアップ";
      }
    } else if (message === "nickname") {
      if (lang === "ko") {
        returnMsg = "닉네임";
      } else if (lang === "en") {
        returnMsg = "Nickname";
      } else if (lang === "ch") {
        returnMsg = "昵称";
      } else if (lang === "ja") {
        returnMsg = "ニックネーム";
      }
    } else if (message === "confirm_password") {
      if (lang === "ko") {
        returnMsg = "비밀번호확인";
      } else if (lang === "en") {
        returnMsg = "Confirm Password";
      } else if (lang === "ch") {
        returnMsg = "确认密码";
      } else if (lang === "ja") {
        returnMsg = "パスワードを認証する";
      }
    } else if (message === "mobile") {
      if (lang === "ko") {
        returnMsg = "핸드폰번호";
      } else if (lang === "en") {
        returnMsg = "Mobile";
      } else if (lang === "ch") {
        returnMsg = "移动";
      } else if (lang === "ja") {
        returnMsg = "モバイル";
      }
    } else if (message === "referral_code") {
      if (lang === "ko") {
        returnMsg = "추천인코드";
      } else if (lang === "en") {
        returnMsg = "Referral Code";
      } else if (lang === "ch") {
        returnMsg = "推荐的代码";
      } else if (lang === "ja") {
        returnMsg = "紹介コード";
      }
    } else if (message === "bank_name") {
      if (lang === "ko") {
        returnMsg = "출금은행";
      } else if (lang === "en") {
        returnMsg = "Bank Name";
      } else if (lang === "ch") {
        returnMsg = "银行名";
      } else if (lang === "ja") {
        returnMsg = "銀行名";
      }
    } else if (message === "bank_account_name") {
      if (lang === "ko") {
        returnMsg = "출금예금주명";
      } else if (lang === "en") {
        returnMsg = "Bank Account Name";
      } else if (lang === "ch") {
        returnMsg = "银行账户名称";
      } else if (lang === "ja") {
        returnMsg = "銀行口座名";
      }
    } else if (message === "bank_account_no") {
      if (lang === "ko") {
        returnMsg = "출금계좌번호";
      } else if (lang === "en") {
        returnMsg = "Bank Account No.";
      } else if (lang === "ch") {
        returnMsg = "银行编号";
      } else if (lang === "ja") {
        returnMsg = "銀行の口座番号";
      }
    } else if (message === "cashout_pin") {
      if (lang === "ko") {
        returnMsg = "출금비번";
      } else if (lang === "en") {
        returnMsg = "Cash Out PIN";
      } else if (lang === "ch") {
        returnMsg = "银行取款钉";
      } else if (lang === "ja") {
        returnMsg = "キャッシュアウトピン";
      }
    } else if (message === "cancel") {
      if (lang === "ko") {
        returnMsg = "취소하기";
      } else if (lang === "en") {
        returnMsg = "Cancel";
      } else if (lang === "ch") {
        returnMsg = "取消";
      } else if (lang === "ja") {
        returnMsg = "キャンセル";
      }
    } else if (message === "enter_id") {
      if (lang === "ko") {
        returnMsg = "아이디를 입력하세요";
      } else if (lang === "en") {
        returnMsg = "Please enter your ID";
      } else if (lang === "ch") {
        returnMsg = "请输入您的用户名";
      } else if (lang === "ja") {
        returnMsg = "IDを入力してください";
      }
    } else if (message === "enter_password") {
      if (lang === "ko") {
        returnMsg = "비밀번호를 입력하세요";
      } else if (lang === "en") {
        returnMsg = "Please enter your password";
      } else if (lang === "ch") {
        returnMsg = "请输入您的密码";
      } else if (lang === "ja") {
        returnMsg = "パスワードを入力してください";
      }
    } else if (message === "signup_subtitle") {
      if (lang === "ko") {
        returnMsg = "가입회원정보를 입력해 주세요.";
      } else if (lang === "en") {
        returnMsg = "Please enter your subscription information.";
      } else if (lang === "ch") {
        returnMsg = "请输入您的订阅信息。";
      } else if (lang === "ja") {
        returnMsg = "定期購読情報を入力してください。";
      }
    } else if (message === "signup_message") {
      if (lang === "ko") {
        returnMsg =
          "가입승인 절차상 통화가 필요하오니 정확히 기재해 주시기 바랍니다.";
      } else if (lang === "en") {
        returnMsg = "Please be sure to fill out the registration form.";
      } else if (lang === "ch") {
        returnMsg = "请务必填写注册表";
      } else if (lang === "ja") {
        returnMsg = "登録用紙に必ず記入してください。";
      }
    } else if (message === "mainmenu") {
      if (lang === "ko") {
        returnMsg = "메인메뉴";
      } else if (lang === "en") {
        returnMsg = "MAIN MENU";
      } else if (lang === "ch") {
        returnMsg = "请输入内容";
      } else if (lang === "ja") {
        returnMsg = "メインメニュー";
      }
    } else if (message === "confirm") {
      //확인
      if (lang === "ko") {
        returnMsg = "확인";
      } else if (lang === "en") {
        returnMsg = "Check";
      } else if (lang === "ch") {
        returnMsg = "确认";
      } else if (lang === "ja") {
        returnMsg = "確認";
      }
    } else if (message === "message1") {
      //아이디를 입력 또는 3자리 이상 20자리 이하로 작성해주세요
      if (lang === "ko") {
        returnMsg = "아이디를 입력 또는 3자리 이상 20자리 이하로 작성해주세요";
      } else if (lang === "en") {
        returnMsg = "Please enter your ID or write down 3 or 20 digits.";
      } else if (lang === "ch") {
        returnMsg = "请输入ID或3位以上20位以下。";
      } else if (lang === "ja") {
        returnMsg = "IDを入力または3桁以上20桁以下に作成してください。";
      }
    } else if (message === "message2") {
      //사용 할수있는 아이디 입니다.
      if (lang === "ko") {
        returnMsg = "사용 할수있는 아이디 입니다.";
      } else if (lang === "en") {
        returnMsg = "The ID is available for use.";
      } else if (lang === "ch") {
        returnMsg = "是可以使用的ID。";
      } else if (lang === "ja") {
        returnMsg = "使用できるIDです。";
      }
    } else if (message === "message3") {
      //사용 불가능한 아이디 입니다.
      if (lang === "ko") {
        returnMsg = "사용 불가능한 아이디 입니다.";
      } else if (lang === "en") {
        returnMsg = "This ID is not available.";
      } else if (lang === "ch") {
        returnMsg = "无法使用的ID。";
      } else if (lang === "ja") {
        returnMsg = "使用不可能なIDです。";
      }
    } else if (message === "message4") {
      //아이디를 입력 또는 3자리 이상 20자리 이하로 작성해주세요
      if (lang === "ko") {
        returnMsg = "아이디를 입력 또는 3자리 이상 20자리 이하로 작성해주세요";
      } else if (lang === "en") {
        returnMsg = "Please enter your ID or write down 3 or 20 digits.";
      } else if (lang === "ch") {
        returnMsg = "请输入ID或3位以上20位以下。";
      } else if (lang === "ja") {
        returnMsg = "IDを入力または3桁以上20桁以下に作成してください。";
      }
    } else if (message === "message5") {
      //비밀번호를 확인해주세요
      if (lang === "ko") {
        returnMsg = "비밀번호를 확인해주세요";
      } else if (lang === "en") {
        returnMsg = "Please check the password.";
      } else if (lang === "ch") {
        returnMsg = "请确认密码";
      } else if (lang === "ja") {
        returnMsg = "パスワードを確認してください。";
      }
    } else if (message === "message6") {
      //휴대폰 번호를 확인해주세요
      if (lang === "ko") {
        returnMsg = "휴대폰 번호를 확인해주세요";
      } else if (lang === "en") {
        returnMsg = "Please check your phone number.";
      } else if (lang === "ch") {
        returnMsg = "请确认手机号";
      } else if (lang === "ja") {
        returnMsg = "携帯電話番号を確認してください。";
      }
    } else if (message === "message7") {
      //은행명을 확인해주세요
      if (lang === "ko") {
        returnMsg = "은행명을 확인해주세요";
      } else if (lang === "en") {
        returnMsg = "Please check the bank name.";
      } else if (lang === "ch") {
        returnMsg = "请确认银行名。";
      } else if (lang === "ja") {
        returnMsg = "銀行名を確認してください。";
      }
    } else if (message === "message8") {
      //계좌 번호를  확인해주세요
      if (lang === "ko") {
        returnMsg = "계좌 번호를  확인해주세요";
      } else if (lang === "en") {
        returnMsg = "Please check your bank account number.";
      } else if (lang === "ch") {
        returnMsg = "请确认账号";
      } else if (lang === "ja") {
        returnMsg = "口座番号をご確認ください";
      }
    } else if (message === "message9") {
      //예금주 이름을 확인해주세요
      if (lang === "ko") {
        returnMsg = "예금주 이름을 확인해주세요";
      } else if (lang === "en") {
        returnMsg = "Please check the account holder's name.";
      } else if (lang === "ch") {
        returnMsg = "请确认储户姓名。";
      } else if (lang === "ja") {
        returnMsg = "預金者名を確認してください。";
      }
    }

    return returnMsg;
  };

  getMainMessage = (lang: any, message: any) => {
    let returnMsg = "";

    if (message === "deposite") {
      //입금신청
      if (lang === "ko") {
        returnMsg = "입금신청";
      } else if (lang === "en") {
        returnMsg = "Deposite";
      } else if (lang === "ch") {
        returnMsg = "银行存款";
      } else if (lang === "ja") {
        returnMsg = "入金";
      }
    } else if (message === "withdraw") {
      //출금신청
      if (lang === "ko") {
        returnMsg = "출금신청";
      } else if (lang === "en") {
        returnMsg = "Withdraw";
      } else if (lang === "ch") {
        returnMsg = "银行撤回";
      } else if (lang === "ja") {
        returnMsg = "出金";
      }
    } else if (message === "help") {
      //고객센터
      if (lang === "ko") {
        returnMsg = "고객센터";
      } else if (lang === "en") {
        returnMsg = "Help";
      } else if (lang === "ch") {
        returnMsg = "客服中心";
      } else if (lang === "ja") {
        returnMsg = "カスタマーセンター";
      }
    } else if (message === "notice") {
      //공지사항
      if (lang === "ko") {
        returnMsg = "계좌문의";
      } else if (lang === "en") {
        returnMsg = "Notice";
      } else if (lang === "ch") {
        returnMsg = "公告事项";
      } else if (lang === "ja") {
        returnMsg = "お知らせ事項";
      }
    } else if (message === "message") {
      //쪽지
      if (lang === "ko") {
        returnMsg = "쪽지";
      } else if (lang === "en") {
        returnMsg = "Message";
      } else if (lang === "ch") {
        returnMsg = "信息";
      } else if (lang === "ja") {
        returnMsg = "メッセージ";
      }
    } else if (message === "mypage") {
      //마이페이지
      if (lang === "ko") {
        returnMsg = "마이페이지";
      } else if (lang === "en") {
        returnMsg = "My Page";
      } else if (lang === "ch") {
        returnMsg = "我的页面";
      } else if (lang === "ja") {
        returnMsg = "マイページ";
      }
    } else if (message === "logout") {
      //로그아웃
      if (lang === "ko") {
        returnMsg = "로그아웃";
      } else if (lang === "en") {
        returnMsg = "Logout";
      } else if (lang === "ch") {
        returnMsg = "登出";
      } else if (lang === "ja") {
        returnMsg = "ログアウト";
      }
    } else if (message === "sir") {
      //님
      if (lang === "ko") {
        returnMsg = "님";
      } else if (lang === "en") {
        returnMsg = "Sir";
      } else if (lang === "ch") {
        returnMsg = "先生";
      } else if (lang === "ja") {
        returnMsg = "様";
      }
    } else if (message === "teamviewer") {
      //팀뷰어 다운로드
      if (lang === "ko") {
        returnMsg = "팀뷰어 다운로드";
      } else if (lang === "en") {
        returnMsg = "Download Teamviewer";
      } else if (lang === "ch") {
        returnMsg = "下载 的TeamViewer";
      } else if (lang === "ja") {
        returnMsg = "TeamViewerダウンロード";
      }
    } else if (message === "24hr") {
      //24시고객상담
      if (lang === "ko") {
        returnMsg = "24시고객상담";
      } else if (lang === "en") {
        returnMsg = "24hr Customer Consultation";
      } else if (lang === "ch") {
        returnMsg = "24小时客户咨询";
      } else if (lang === "ja") {
        returnMsg = "24時間カスタマーサービス";
      }
    } else if (message === "entergame") {
      //게임입장하기
      if (lang === "ko") {
        returnMsg = "게임입장하기";
      } else if (lang === "en") {
        returnMsg = "Enter Game";
      } else if (lang === "ch") {
        returnMsg = "进入游戏";
      } else if (lang === "ja") {
        returnMsg = "ゲーム入場する";
      }
    } else if (message === "entergame2") {
      //입장
      if (lang === "ko") {
        returnMsg = "입장";
      } else if (lang === "en") {
        returnMsg = "Enters";
      } else if (lang === "ch") {
        returnMsg = "进入游戏";
      } else if (lang === "ja") {
        returnMsg = "立場";
      }
    } else if (message === "login") {
      //로그인
      if (lang === "ko") {
        returnMsg = "로그인";
      } else if (lang === "en") {
        returnMsg = "Login";
      } else if (lang === "ch") {
        returnMsg = "登录";
      } else if (lang === "ja") {
        returnMsg = "ログイン";
      }
    } else if (message === "alertmsg") {
      //로그인 이후 이용가능한 기능입니다
      if (lang === "ko") {
        returnMsg = "로그인 이후 이용가능한 기능입니다";
      } else if (lang === "en") {
        returnMsg = "It's a feature that you can use after logging in.";
      } else if (lang === "ch") {
        returnMsg = "登录后可使用的功能。";
      } else if (lang === "ja") {
        returnMsg = "ログインしてから利用できる機能です。";
      }
    } else if (message === "confirm") {
      //확인
      if (lang === "ko") {
        returnMsg = "확인";
      } else if (lang === "en") {
        returnMsg = "Check";
      } else if (lang === "ch") {
        returnMsg = "确认";
      } else if (lang === "ja") {
        returnMsg = "確認";
      }
    }

    return returnMsg;
  };

  getDepositMessage = (lang: any, message: any) => {
    let returnMsg = "";

    if (message === "deposit") {
      //입금신청
      if (lang === "ko") {
        returnMsg = "입금신청";
      } else if (lang === "en") {
        returnMsg = "DEPOSIT REQUEST";
      } else if (lang === "ch") {
        returnMsg = "个存款请求";
      } else if (lang === "ja") {
        returnMsg = "入金依頼";
      }
    } else if (message === "precautions") {
      //주의사항
      if (lang === "ko") {
        returnMsg = "주의사항";
      } else if (lang === "en") {
        returnMsg = "Precautions";
      } else if (lang === "ch") {
        returnMsg = "注意事项";
      } else if (lang === "ja") {
        returnMsg = "注意事項";
      }
    } else if (message === "precautions1") {
      //주의사항 내용
      if (lang === "ko") {
        returnMsg =
          "고객님의 입금정보 내역을 꼭 확인하시기 바랍니다. 입금자명과 계좌번호가 다를결우 입금처리가 되지 않습니다. 출금하실때에도 입금계좌와 동일할 계좌로만 출금이 됩니다.";
      } else if (lang === "en") {
        returnMsg =
          "Please make sure to check the name of the bank and the bank that will receive the deposit when applying for withdrawal.";
      } else if (lang === "ch") {
        returnMsg = "申请提款时，请务必查看银行名称和将收到押金的银行。";
      } else if (lang === "ja") {
        returnMsg =
          "出金は1万ウォン単位でのみお申し込み可能です。出金申請時入金受ける銀行と入金者名を必ず確認してください。";
      }
    } else if (message === "precautions2") {
      //주의사항 내용2
      if (lang === "ko") {
        returnMsg =
          "입금전 반드시 계좌문의를 문의하시고, 입금하시기 바랍니다. 계좌가 수시로 변경될수 있으니 문의없이 입금하신 회원님의 입금건에 대해서는 절대 책임지지 않습니다.";
      } else if (lang === "en") {
        returnMsg =
          "Please make sure to contact us before making the deposit. Because the account may change from time to time, we are not responsible for the deposit of the customer who made the deposit without inquiry.";
      } else if (lang === "ch") {
        returnMsg =
          "请务必在存款前与我们联系。由于帐户可能会不时更改，因此我们不对未经查询进行存款的客户的存款负责。";
      } else if (lang === "ja") {
        returnMsg =
          "入金前に必ず口座お問い合わせいただき、ご入金してください。口座が頻繁に変更されるので、お問い合わせなしに入金された会員の入金には責任を負いかねます。";
      }
    } else if (message === "bank") {
      //입금 은행명
      if (lang === "ko") {
        returnMsg = "입금 은행명";
      } else if (lang === "en") {
        returnMsg = "Deposit Bank Name";
      } else if (lang === "ch") {
        returnMsg = "存款银行名称";
      } else if (lang === "ja") {
        returnMsg = "入金銀行名";
      }
    } else if (message === "depositor") {
      //입금자명
      if (lang === "ko") {
        returnMsg = "입금자명";
      } else if (lang === "en") {
        returnMsg = "Depositor Name";
      } else if (lang === "ch") {
        returnMsg = "存款人姓名";
      } else if (lang === "ja") {
        returnMsg = "入金名義名";
      }
    } else if (message === "amount") {
      //입금할 금액
      if (lang === "ko") {
        returnMsg = "입금할 금액";
      } else if (lang === "en") {
        returnMsg = "Amount to Deposit";
      } else if (lang === "ch") {
        returnMsg = "存款金额";
      } else if (lang === "ja") {
        returnMsg = "入金額";
      }
    } else if (message === "10k") {
      //1만
      if (lang === "ko") {
        returnMsg = "1만";
      } else if (lang === "en") {
        returnMsg = "10K";
      } else if (lang === "ch") {
        returnMsg = "100万";
      } else if (lang === "ja") {
        returnMsg = "1万";
      }
    } else if (message === "50k") {
      //5만
      if (lang === "ko") {
        returnMsg = "5만";
      } else if (lang === "en") {
        returnMsg = "50K";
      } else if (lang === "ch") {
        returnMsg = "500万";
      } else if (lang === "ja") {
        returnMsg = "5万";
      }
    } else if (message === "100k") {
      //10만
      if (lang === "ko") {
        returnMsg = "10만";
      } else if (lang === "en") {
        returnMsg = "100K";
      } else if (lang === "ch") {
        returnMsg = "1000万";
      } else if (lang === "ja") {
        returnMsg = "10万";
      }
    } else if (message === "500k") {
      //50만
      if (lang === "ko") {
        returnMsg = "50만";
      } else if (lang === "en") {
        returnMsg = "500K";
      } else if (lang === "ch") {
        returnMsg = "5000万";
      } else if (lang === "ja") {
        returnMsg = "50万";
      }
    } else if (message === "1000k") {
      //100만
      if (lang === "ko") {
        returnMsg = "100만";
      } else if (lang === "en") {
        returnMsg = "1000K";
      } else if (lang === "ch") {
        returnMsg = "10000万";
      } else if (lang === "ja") {
        returnMsg = "100万";
      }
    } else if (message === "clear") {
      //정정
      if (lang === "ko") {
        returnMsg = "정정";
      } else if (lang === "en") {
        returnMsg = "Clear";
      } else if (lang === "ch") {
        returnMsg = "更正";
      } else if (lang === "ja") {
        returnMsg = "訂正";
      }
    } else if (message === "apply") {
      //신청하기
      if (lang === "ko") {
        returnMsg = "신청하기";
      } else if (lang === "en") {
        returnMsg = "Apply";
      } else if (lang === "ch") {
        returnMsg = "应用";
      } else if (lang === "ja") {
        returnMsg = "適用";
      }
    } else if (message === "confirm") {
      //확인
      if (lang === "ko") {
        returnMsg = "확인";
      } else if (lang === "en") {
        returnMsg = "Confirm";
      } else if (lang === "ch") {
        returnMsg = "确认";
      } else if (lang === "ja") {
        returnMsg = "確認";
      }
    } else if (message === "message1") {
      //계좌문의에 성공하였습니다.
      if (lang === "ko") {
        returnMsg = "계좌문의에 성공하였습니다.";
      } else if (lang === "en") {
        returnMsg = "Your account inquiry was successful.";
      } else if (lang === "ch") {
        returnMsg = "账户咨询成功。";
      } else if (lang === "ja") {
        returnMsg = "口座からのお問い合わせに成功しました。";
      }
    } else if (message === "message2") {
      //로그인후 이용해주세요.
      if (lang === "ko") {
        returnMsg = "로그인후 이용해주세요.";
      } else if (lang === "en") {
        returnMsg = "Please log in and use it.";
      } else if (lang === "ch") {
        returnMsg = "请登录后使用。";
      } else if (lang === "ja") {
        returnMsg = "ログインしてご利用ください。";
      }
    } else if (message === "message3") {
      //입금금액을 입력해주세요.
      if (lang === "ko") {
        returnMsg = "입금금액을 입력해주세요.";
      } else if (lang === "en") {
        returnMsg = "Please enter the deposit amount.";
      } else if (lang === "ch") {
        returnMsg = "请输入汇款金额。";
      } else if (lang === "ja") {
        returnMsg = "入金金額を入力してください。";
      }
    } else if (message === "message4") {
      //최소 입금금액은 삼만원입니다.
      if (lang === "ko") {
        returnMsg = "최소 입금금액은 삼만원입니다.";
      } else if (lang === "en") {
        returnMsg = "The minimum deposit amount is 30,000 won.";
      } else if (lang === "ch") {
        returnMsg = "最小汇款金额为3万韩元。";
      } else if (lang === "ja") {
        returnMsg = "最低入金金額は三万ウォンです。";
      }
    } else if (message === "message5") {
      //입금은 만원 단위로 가능합니다.
      if (lang === "ko") {
        returnMsg = "입금은 만원 단위로 가능합니다.";
      } else if (lang === "en") {
        returnMsg = "You can deposit money in units of 10,000 won.";
      } else if (lang === "ch") {
        returnMsg = "汇款可以1万元单位";
      } else if (lang === "ja") {
        returnMsg = "入金は一万ウォン単位で可能です。";
      }
    } else if (message === "message6") {
      //입금신청을 성공하였습니다.
      if (lang === "ko") {
        returnMsg = "입금신청을 성공하였습니다.";
      } else if (lang === "en") {
        returnMsg = "The deposit application was successful.";
      } else if (lang === "ch") {
        returnMsg = "存款申请成功。";
      } else if (lang === "ja") {
        returnMsg = "入金申し込みに成功しました。";
      }
    } else if (message === "message7") {
      //대기중인 입금신청이 있습니다.
      if (lang === "ko") {
        returnMsg = "대기중인 입금신청이 있습니다.";
      } else if (lang === "en") {
        returnMsg = "There's a request for deposit waiting.";
      } else if (lang === "ch") {
        returnMsg = "有等待中的汇款申请。";
      } else if (lang === "ja") {
        returnMsg = "入金申し込みは待機中です";
      }
    } else if (message === "message8") {
      //알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.
      if (lang === "ko") {
        returnMsg =
          "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.";
      } else if (lang === "en") {
        returnMsg =
          "I thought of an unknown YERRA. If the problem persists, please contact the manager.";
      } else if (lang === "ch") {
        returnMsg = "一个未知的例子发祥了 若问题持续，请咨询管理人员。";
      } else if (lang === "ja") {
        returnMsg =
          "知られざるイエ-ロが発祥しました 問題が続く場合には、管理者に問い合わせてください。";
      }
    }

    return returnMsg;
  };

  getWithdrawMessage = (lang: any, message: any) => {
    let returnMsg = "";

    if (message === "withdraw") {
      //출금신청
      if (lang === "ko") {
        returnMsg = "출금신청";
      } else if (lang === "en") {
        returnMsg = "WITHDRAW";
      } else if (lang === "ch") {
        returnMsg = "银行撤回";
      } else if (lang === "ja") {
        returnMsg = "出金";
      }
    } else if (message === "precautions") {
      //주의사항
      if (lang === "ko") {
        returnMsg = "주의사항";
      } else if (lang === "en") {
        returnMsg = "Precautions";
      } else if (lang === "ch") {
        returnMsg = "注意事项";
      } else if (lang === "ja") {
        returnMsg = "注意事項";
      }
    } else if (message === "precautions1") {
      //주의사항 내용
      if (lang === "ko") {
        returnMsg =
          "출금은 1만원 단위로만 신청 가능합니다. 출금신청시 입금 받으실 은행과 입금자 명을 꼭 확인하시기 바랍니다.";
      } else if (lang === "en") {
        returnMsg =
          "The withdrawal can only be applied in units of 10,000 won. Please make sure to check the name of the bank and the bank that will receive the deposit when applying for withdrawal.";
      } else if (lang === "ch") {
        returnMsg =
          "提款只能以10,000韩元为单位。申请提款时，请务必查看银行名称和将收到押金的银行。";
      } else if (lang === "ja") {
        returnMsg =
          "出金は1万ウォン単位でのみお申し込み可能です。出金申請時入金受ける銀行と入金者名を必ず確認してください。";
      }
    } else if (message === "holdamount") {
      //보유금액
      if (lang === "ko") {
        returnMsg = "보유금액";
      } else if (lang === "en") {
        returnMsg = "Hold Amount";
      } else if (lang === "ch") {
        returnMsg = "持有量";
      } else if (lang === "ja") {
        returnMsg = "保留金額";
      }
    } else if (message === "depositorname") {
      //입금자명
      if (lang === "ko") {
        returnMsg = "입금자명";
      } else if (lang === "en") {
        returnMsg = "Depositor Name";
      } else if (lang === "ch") {
        returnMsg = "存款人姓名";
      } else if (lang === "ja") {
        returnMsg = "入金名義名";
      }
    } else if (message === "bankname") {
      //입금 은행명
      if (lang === "ko") {
        returnMsg = "입금 은행명";
      } else if (lang === "en") {
        returnMsg = "Deposit Bank Name";
      } else if (lang === "ch") {
        returnMsg = "存款银行名称";
      } else if (lang === "ja") {
        returnMsg = "入金銀行名";
      }
    } else if (message === "amountwithdraw") {
      //출금하실 금액
      if (lang === "ko") {
        returnMsg = "출금하실 금액";
      } else if (lang === "en") {
        returnMsg = "Amount to Withdraw";
      } else if (lang === "ch") {
        returnMsg = "可提取金额";
      } else if (lang === "ja") {
        returnMsg = "出金額";
      }
    } else if (message === "10k") {
      //1만
      if (lang === "ko") {
        returnMsg = "1만";
      } else if (lang === "en") {
        returnMsg = "10K";
      } else if (lang === "ch") {
        returnMsg = "100万";
      } else if (lang === "ja") {
        returnMsg = "1万";
      }
    } else if (message === "50k") {
      //5만
      if (lang === "ko") {
        returnMsg = "5만";
      } else if (lang === "en") {
        returnMsg = "50K";
      } else if (lang === "ch") {
        returnMsg = "500万";
      } else if (lang === "ja") {
        returnMsg = "5万";
      }
    } else if (message === "100k") {
      //10만
      if (lang === "ko") {
        returnMsg = "10만";
      } else if (lang === "en") {
        returnMsg = "100K";
      } else if (lang === "ch") {
        returnMsg = "1000万";
      } else if (lang === "ja") {
        returnMsg = "10万";
      }
    } else if (message === "500k") {
      //50만
      if (lang === "ko") {
        returnMsg = "50만";
      } else if (lang === "en") {
        returnMsg = "500K";
      } else if (lang === "ch") {
        returnMsg = "5000万";
      } else if (lang === "ja") {
        returnMsg = "50万";
      }
    } else if (message === "1000k") {
      //100만
      if (lang === "ko") {
        returnMsg = "100만";
      } else if (lang === "en") {
        returnMsg = "1000K";
      } else if (lang === "ch") {
        returnMsg = "10000万";
      } else if (lang === "ja") {
        returnMsg = "100万";
      }
    } else if (message === "clear") {
      //정정
      if (lang === "ko") {
        returnMsg = "정정";
      } else if (lang === "en") {
        returnMsg = "Clear";
      } else if (lang === "ch") {
        returnMsg = "更正";
      } else if (lang === "ja") {
        returnMsg = "訂正";
      }
    } else if (message === "apply") {
      //신청하기
      if (lang === "ko") {
        returnMsg = "신청하기";
      } else if (lang === "en") {
        returnMsg = "Apply";
      } else if (lang === "ch") {
        returnMsg = "应用";
      } else if (lang === "ja") {
        returnMsg = "適用";
      }
    } else if (message === "confirm") {
      //확인
      if (lang === "ko") {
        returnMsg = "확인";
      } else if (lang === "en") {
        returnMsg = "Confirm";
      } else if (lang === "ch") {
        returnMsg = "确认";
      } else if (lang === "ja") {
        returnMsg = "確認";
      }
    } else if (message === "message1") {
      //출금은 만원 단위로 가능합니다.
      if (lang === "ko") {
        returnMsg = "출금은 만원 단위로 가능합니다.";
      } else if (lang === "en") {
        returnMsg = "You can withdraw money by 10,000 won.";
      } else if (lang === "ch") {
        returnMsg = "可以以1万元为单位付款。";
      } else if (lang === "ja") {
        returnMsg = "出金は1万ウォン単位で可能です。";
      }
    } else if (message === "message2") {
      //출금금액을 입력해주세요.
      if (lang === "ko") {
        returnMsg = "출금금액을 입력해주세요.";
      } else if (lang === "en") {
        returnMsg = "Please enter the withdrawal amount.";
      } else if (lang === "ch") {
        returnMsg = "请输入出纳金额。";
      } else if (lang === "ja") {
        returnMsg = "出金額を入力してください。";
      }
    } else if (message === "message3") {
      //출금신청을 성공하였습니다.
      if (lang === "ko") {
        returnMsg = "출금신청을 성공하였습니다.";
      } else if (lang === "en") {
        returnMsg = "The withdrawal application was successful.";
      } else if (lang === "ch") {
        returnMsg = "取款申请成功。";
      } else if (lang === "ja") {
        returnMsg = "出金申請に成功しました。";
      }
    } else if (message === "message4") {
      //보유중인 금액보다 출금신청금액이 많습니다.
      if (lang === "ko") {
        returnMsg = "보유중인 금액보다 출금신청금액이 많습니다.";
      } else if (lang === "en") {
        returnMsg =
          "The amount of withdrawal application is higher than the amount you have.";
      } else if (lang === "ch") {
        returnMsg = "申请付款金额多于持有金额。";
      } else if (lang === "ja") {
        returnMsg = "保有中の金額より出金申請金額が多いです。";
      }
    } else if (message === "message5") {
      //대기중인 출금신청이 있습니다.
      if (lang === "ko") {
        returnMsg = "대기중인 출금신청이 있습니다.";
      } else if (lang === "en") {
        returnMsg = "There is a pending withdrawal application.";
      } else if (lang === "ch") {
        returnMsg = "有待命的取款申请。";
      } else if (lang === "ja") {
        returnMsg = "待機中の出金申請があります。";
      }
    } else if (message === "message6") {
      //알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.
      if (lang === "ko") {
        returnMsg =
          "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.";
      } else if (lang === "en") {
        returnMsg =
          "I thought of an unknown YERRA. If the problem persists, please contact the manager.";
      } else if (lang === "ch") {
        returnMsg = "一个未知的例子发祥了 若问题持续，请咨询管理人员。";
      } else if (lang === "ja") {
        returnMsg =
          "知られざるイエ-ロが発祥しました 問題が続く場合には、管理者に問い合わせてください。";
      }
    }

    return returnMsg;
  };

  getMypageMessage = (lang: any, message: any) => {
    let returnMsg = "";

    if (message === "mypage") {
      //마이페이지
      if (lang === "ko") {
        returnMsg = "마이페이지";
      } else if (lang === "en") {
        returnMsg = "MY PAGE";
      } else if (lang === "ch") {
        returnMsg = "我的页面";
      } else if (lang === "ja") {
        returnMsg = "マイページ";
      }
    } else if (message === "all") {
      //전체
      if (lang === "ko") {
        returnMsg = "전체";
      } else if (lang === "en") {
        returnMsg = "ALL";
      } else if (lang === "ch") {
        returnMsg = "所有";
      } else if (lang === "ja") {
        returnMsg = "すべて";
      }
    } else if (message === "changepassword") {
      //비밀번호 변경
      if (lang === "ko") {
        returnMsg = "비밀번호 변경";
      } else if (lang === "en") {
        returnMsg = "Change Password";
      } else if (lang === "ch") {
        returnMsg = "更改密码";
      } else if (lang === "ja") {
        returnMsg = "パスワード変更";
      }
    } else if (message === "pointmanagement") {
      //금고
      if (lang === "ko") {
        returnMsg = "금고";
      } else if (lang === "en") {
        returnMsg = "Point Management";
      } else if (lang === "ch") {
        returnMsg = "保险箱";
      } else if (lang === "ja") {
        returnMsg = "金庫";
      }
    } else if (message === "id") {
      //아이디
      if (lang === "ko") {
        returnMsg = "아이디";
      } else if (lang === "en") {
        returnMsg = "ID";
      } else if (lang === "ch") {
        returnMsg = "用户名";
      } else if (lang === "ja") {
        returnMsg = "アイディー";
      }
    } else if (message === "holdamount") {
      //보유금액
      if (lang === "ko") {
        returnMsg = "보유금액";
      } else if (lang === "en") {
        returnMsg = "Hold Amount";
      } else if (lang === "ch") {
        returnMsg = "持有金额";
      } else if (lang === "ja") {
        returnMsg = "保有金額";
      }
    } else if (message === "money") {
      //금고금액
      if (lang === "ko") {
        returnMsg = "금고금액";
      } else if (lang === "en") {
        returnMsg = "money in the safe";
      } else if (lang === "ch") {
        returnMsg = "保险柜金额";
      } else if (lang === "ja") {
        returnMsg = "禁固金額";
      }
    } else if (message === "accountholder") {
      //예금주명
      if (lang === "ko") {
        returnMsg = "예금주명";
      } else if (lang === "en") {
        returnMsg = "Name of the account holder";
      } else if (lang === "ch") {
        returnMsg = "储户名";
      } else if (lang === "ja") {
        returnMsg = "預金者名";
      }
    } else if (message === "bank") {
      //회원은행
      if (lang === "ko") {
        returnMsg = "회원은행";
      } else if (lang === "en") {
        returnMsg = "Member bank";
      } else if (lang === "ch") {
        returnMsg = "会员银行";
      } else if (lang === "ja") {
        returnMsg = "会員銀行";
      }
    } else if (message === "account") {
      //출금 계좌번호
      if (lang === "ko") {
        returnMsg = "출금 계좌번호";
      } else if (lang === "en") {
        returnMsg = "Withdrawal account number";
      } else if (lang === "ch") {
        returnMsg = "取款账号";
      } else if (lang === "ja") {
        returnMsg = "出金口座番号";
      }
    } else if (message === "newpassword") {
      //신규비밀번호
      if (lang === "ko") {
        returnMsg = "신규비밀번호";
      } else if (lang === "en") {
        returnMsg = "New Password";
      } else if (lang === "ch") {
        returnMsg = "新密码";
      } else if (lang === "ja") {
        returnMsg = "新しいパスワード";
      }
    } else if (message === "confirmpassword") {
      //비밀번호확인
      if (lang === "ko") {
        returnMsg = "비밀번호확인";
      } else if (lang === "en") {
        returnMsg = "Confirm Password";
      } else if (lang === "ch") {
        returnMsg = "密码确认";
      } else if (lang === "ja") {
        returnMsg = "パスワード確認";
      }
    } else if (message === "password1") {
      //새 비밀번호를 입력해주세요
      if (lang === "ko") {
        returnMsg = "새 비밀번호를 입력해주세요";
      } else if (lang === "en") {
        returnMsg = "Please enter a new password";
      } else if (lang === "ch") {
        returnMsg = "请输入新密码。";
      } else if (lang === "ja") {
        returnMsg = "新しいパスワードを入力してください。";
      }
    } else if (message === "password2") {
      //새 비밀번호를 다시 입력해주세요
      if (lang === "ko") {
        returnMsg = "새 비밀번호를 다시 입력해주세요";
      } else if (lang === "en") {
        returnMsg = "Please enter the new password again";
      } else if (lang === "ch") {
        returnMsg = "请重新输入新的密码";
      } else if (lang === "ja") {
        returnMsg = "新しいパスワードをもう一度入力してください。";
      }
    } else if (message === "update") {
      //업데이트
      if (lang === "ko") {
        returnMsg = "업데이트";
      } else if (lang === "en") {
        returnMsg = "Update";
      } else if (lang === "ch") {
        returnMsg = "更新";
      } else if (lang === "ja") {
        returnMsg = "アップデート";
      }
    } else if (message === "safe1") {
      //금고입금
      if (lang === "ko") {
        returnMsg = "금고입금";
      } else if (lang === "en") {
        returnMsg = "Deposit in the safe";
      } else if (lang === "ch") {
        returnMsg = "金库入账";
      } else if (lang === "ja") {
        returnMsg = "金庫入金";
      }
    } else if (message === "safe2") {
      //금고출근
      if (lang === "ko") {
        returnMsg = "금고출금";
      } else if (lang === "en") {
        returnMsg = "Withdrawal from the safe";
      } else if (lang === "ch") {
        returnMsg = "金库出金";
      } else if (lang === "ja") {
        returnMsg = "金庫出金";
      }
    } else if (message === "depositmoney") {
      //입금하기
      if (lang === "ko") {
        returnMsg = "입금하기";
      } else if (lang === "en") {
        returnMsg = "Deposit money";
      } else if (lang === "ch") {
        returnMsg = "汇款";
      } else if (lang === "ja") {
        returnMsg = "入金する";
      }
    } else if (message === "withdrawmoney") {
      //출금하기
      if (lang === "ko") {
        returnMsg = "출금하기";
      } else if (lang === "en") {
        returnMsg = "Withdraw money";
      } else if (lang === "ch") {
        returnMsg = "禁止出境";
      } else if (lang === "ja") {
        returnMsg = "出金する";
      }
    } else if (message === "passwordresult1") {
      //출금하기
      if (lang === "ko") {
        returnMsg = "위에 입력하신 비밀번호와 일치하지 않습니다.";
      } else if (lang === "en") {
        returnMsg = "It doesn't match the password you entered above.";
      } else if (lang === "ch") {
        returnMsg = "与上面输入的密码不一致。";
      } else if (lang === "ja") {
        returnMsg = "上記に入力したパスワードと一致しません。";
      }
    } else if (message === "passwordresult2") {
      //출금하기
      if (lang === "ko") {
        returnMsg = "위에 입력하신 비밀번호와 일치합니다.";
      } else if (lang === "en") {
        returnMsg = "It matches the password you entered above.";
      } else if (lang === "ch") {
        returnMsg = "与上面输入的密码一致。";
      } else if (lang === "ja") {
        returnMsg = "上記に入力したパスワードと一致します。";
      }
    } else if (message === "confirm") {
      //확인
      if (lang === "ko") {
        returnMsg = "확인";
      } else if (lang === "en") {
        returnMsg = "Confirm";
      } else if (lang === "ch") {
        returnMsg = "确认";
      } else if (lang === "ja") {
        returnMsg = "確認";
      }
    } else if (message === "cancel") {
      //취소
      if (lang === "ko") {
        returnMsg = "취소";
      } else if (lang === "en") {
        returnMsg = "Cancel";
      } else if (lang === "ch") {
        returnMsg = "取消";
      } else if (lang === "ja") {
        returnMsg = "キャンセル";
      }
    } else if (message === "message1") {
      //벨런스를 입력해주세요.
      if (lang === "ko") {
        returnMsg = "벨런스를 입력해주세요.";
      } else if (lang === "en") {
        returnMsg = "Please enter the balance.";
      } else if (lang === "ch") {
        returnMsg = "请输入均衡。";
      } else if (lang === "ja") {
        returnMsg = "バランスを入力してください。";
      }
    } else if (message === "message2") {
      //보유머니를 입금 하시겠습니까?
      if (lang === "ko") {
        returnMsg = "보유머니를 입금 하시겠습니까?";
      } else if (lang === "en") {
        returnMsg = "Do you want to deposit your money?";
      } else if (lang === "ch") {
        returnMsg = "确定要汇出所有资金吗？";
      } else if (lang === "ja") {
        returnMsg = "保有マネーを入金しますか？";
      }
    } else if (message === "message3") {
      //벨런스를 금고머니로 변경하였습니다.
      if (lang === "ko") {
        returnMsg = "벨런스를 금고머니로 변경하였습니다.";
      } else if (lang === "en") {
        returnMsg = "I changed the balance to safe money.";
      } else if (lang === "ch") {
        returnMsg = "将均衡变更为金库货币。";
      } else if (lang === "ja") {
        returnMsg = "バランスを金庫マネーに変更しました。";
      }
    } else if (message === "message4") {
      //금고머니를 입력해주세요.
      if (lang === "ko") {
        returnMsg = "금고머니를 입력해주세요.";
      } else if (lang === "en") {
        returnMsg = "Please enter the safe money.";
      } else if (lang === "ch") {
        returnMsg = "请输入金库货币。";
      } else if (lang === "ja") {
        returnMsg = "金庫マネーを入力してください。";
      }
    } else if (message === "message5") {
      //금고머니를 출금하시겠습니까?
      if (lang === "ko") {
        returnMsg = "금고머니를 출금하시겠습니까?";
      } else if (lang === "en") {
        returnMsg = "Will you withdraw your safe money?";
      } else if (lang === "ch") {
        returnMsg = "确定要禁止携带金币吗？";
      } else if (lang === "ja") {
        returnMsg = "金庫のお母さんをおろすのですか？";
      }
    } else if (message === "message6") {
      //금고머니를 벨런스로 변경하였습니다.
      if (lang === "ko") {
        returnMsg = "금고머니를 벨런스로 변경하였습니다.";
      } else if (lang === "en") {
        returnMsg = "I changed the safe money to balance.";
      } else if (lang === "ch") {
        returnMsg = "金库货币变更为均衡。";
      } else if (lang === "ja") {
        returnMsg = "金庫マネーをバランスに変更しました。";
      }
    } else if (message === "message7") {
      //비밀번호 입력 또는 3자리 이상 20자리 이하로 작성해주세요
      if (lang === "ko") {
        returnMsg = "비밀번호 입력 또는 3자리 이상 20자리 이하로 작성해주세요";
      } else if (lang === "en") {
        returnMsg = "Please enter your password or write down 3 or 20 digits.";
      } else if (lang === "ch") {
        returnMsg = "请输入密码或3位以上20位以下填写。";
      } else if (lang === "ja") {
        returnMsg = "パスワード入力または3桁以上20桁以下に作成してください。";
      }
    } else if (message === "message8") {
      //비밀번호정보가 동일해야합니다.
      if (lang === "ko") {
        returnMsg = "비밀번호정보가 동일해야합니다.";
      } else if (lang === "en") {
        returnMsg = "Password information must be the same.";
      } else if (lang === "ch") {
        returnMsg = "密码信息必须相同。";
      } else if (lang === "ja") {
        returnMsg = "パスワード情報は同一である必要があります。";
      }
    } else if (message === "message9") {
      //비밀번호정보가 정상적으로 변경되었습니다.
      if (lang === "ko") {
        returnMsg = "비밀번호정보가 정상적으로 변경되었습니다.";
      } else if (lang === "en") {
        returnMsg = "The password information has been changed normally.";
      } else if (lang === "ch") {
        returnMsg = "密码信息已正常变更。";
      } else if (lang === "ja") {
        returnMsg = "パスワード情報が正常に変更されました。";
      }
    } else if (message === "message10") {
      //알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.
      if (lang === "ko") {
        returnMsg =
          "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.";
      } else if (lang === "en") {
        returnMsg =
          "I thought of an unknown YERRA. If the problem persists, please contact the manager.";
      } else if (lang === "ch") {
        returnMsg = "一个未知的例子发祥了 若问题持续，请咨询管理人员。";
      } else if (lang === "ja") {
        returnMsg =
          "知られざるイエ-ロが発祥しました 問題が続く場合には、管理者に問い合わせてください。";
      }
    }

    return returnMsg;
  };

  getBoardMessage = (lang: any, message: any) => {
    let returnMsg = "";

    if (message === "help") {
      //고객센터
      if (lang === "ko") {
        returnMsg = "고객센터";
      } else if (lang === "en") {
        returnMsg = "HELP";
      } else if (lang === "ch") {
        returnMsg = "客服中心";
      } else if (lang === "ja") {
        returnMsg = "カスタマーセンター";
      }
    } else if (message === "title") {
      //제목
      if (lang === "ko") {
        returnMsg = "제목";
      } else if (lang === "en") {
        returnMsg = "Title";
      } else if (lang === "ch") {
        returnMsg = "题目";
      } else if (lang === "ja") {
        returnMsg = "題目";
      }
    } else if (message === "date") {
      //작성일
      if (lang === "ko") {
        returnMsg = "작성일";
      } else if (lang === "en") {
        returnMsg = "Writing date";
      } else if (lang === "ch") {
        returnMsg = "制定日期";
      } else if (lang === "ja") {
        returnMsg = "作成日";
      }
    } else if (message === "status") {
      //상태
      if (lang === "ko") {
        returnMsg = "상태";
      } else if (lang === "en") {
        returnMsg = "Status";
      } else if (lang === "ch") {
        returnMsg = "状态";
      } else if (lang === "ja") {
        returnMsg = "状態";
      }
    } else if (message === "write") {
      //글쓰기
      if (lang === "ko") {
        returnMsg = "글쓰기";
      } else if (lang === "en") {
        returnMsg = "Write";
      } else if (lang === "ch") {
        returnMsg = "";
      } else if (lang === "ja") {
        returnMsg = "";
      }
    } else if (message === "content") {
      //내용
      if (lang === "ko") {
        returnMsg = "내용";
      } else if (lang === "en") {
        returnMsg = "Content";
      } else if (lang === "ch") {
        returnMsg = "写作";
      } else if (lang === "ja") {
        returnMsg = "書き物";
      }
    } else if (message === "back") {
      //뒤로가기
      if (lang === "ko") {
        returnMsg = "뒤로가기";
      } else if (lang === "en") {
        returnMsg = "Back";
      } else if (lang === "ch") {
        returnMsg = "向后走";
      } else if (lang === "ja") {
        returnMsg = "後戻り";
      }
    } else if (message === "save") {
      //저장하기
      if (lang === "ko") {
        returnMsg = "저장하기";
      } else if (lang === "en") {
        returnMsg = "Save";
      } else if (lang === "ch") {
        returnMsg = "保存";
      } else if (lang === "ja") {
        returnMsg = "保存する";
      }
    } else if (message === "delete") {
      //삭제
      if (lang === "ko") {
        returnMsg = "삭제";
      } else if (lang === "en") {
        returnMsg = "Delete";
      } else if (lang === "ch") {
        returnMsg = "删除";
      } else if (lang === "ja") {
        returnMsg = "削除";
      }
    } else if (message === "notice") {
      //공지사항
      if (lang === "ko") {
        returnMsg = "계좌문의";
      } else if (lang === "en") {
        returnMsg = "Notice";
      } else if (lang === "ch") {
        returnMsg = "";
      } else if (lang === "ja") {
        returnMsg = "";
      }
    } else if (message === "message") {
      //쪽지
      if (lang === "ko") {
        returnMsg = "쪽지";
      } else if (lang === "en") {
        returnMsg = "Message";
      } else if (lang === "ch") {
        returnMsg = "公告事项";
      } else if (lang === "ja") {
        returnMsg = "お知らせ事項";
      }
    } else if (message === "check") {
      //확인
      if (lang === "ko") {
        returnMsg = "확인";
      } else if (lang === "en") {
        returnMsg = "Check";
      } else if (lang === "ch") {
        returnMsg = "确认";
      } else if (lang === "ja") {
        returnMsg = "確認";
      }
    } else if (message === "unidentified") {
      //미확인
      if (lang === "ko") {
        returnMsg = "미확인";
      } else if (lang === "en") {
        returnMsg = "Unidentified";
      } else if (lang === "ch") {
        returnMsg = "未经确认";
      } else if (lang === "ja") {
        returnMsg = "未確認";
      }
    } else if (message === "list") {
      //목록
      if (lang === "ko") {
        returnMsg = "목록";
      } else if (lang === "en") {
        returnMsg = "List";
      } else if (lang === "ch") {
        returnMsg = "目录";
      } else if (lang === "ja") {
        returnMsg = "目録";
      }
    } else if (message === "content1") {
      //내용을 입력하세요
      if (lang === "ko") {
        returnMsg = "내용을 입력하세요";
      } else if (lang === "en") {
        returnMsg = "Enter the content";
      } else if (lang === "ch") {
        returnMsg = "请输入内容。";
      } else if (lang === "ja") {
        returnMsg = "内容を入力してください。";
      }
    }

    return returnMsg;
  };

  getTempMessage = (lang: any, message: any) => {
    let returnMsg = "";

    if (message === "") {
      if (lang === "ko") {
        returnMsg = "";
      } else if (lang === "en") {
        returnMsg = "";
      } else if (lang === "ch") {
        returnMsg = "";
      } else if (lang === "ja") {
        returnMsg = "";
      }
    } else if (message === "") {
      if (lang === "ko") {
        returnMsg = "";
      } else if (lang === "en") {
        returnMsg = "";
      } else if (lang === "ch") {
        returnMsg = "";
      } else if (lang === "ja") {
        returnMsg = "";
      }
    }

    return returnMsg;
  };
}
