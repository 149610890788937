import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { confirmAlert } from "react-confirm-alert"; // Import
import { BalanceService } from "../service/balance.service";
import { ConverMoeny } from "../utility/help";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
}

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
  lang: string;
}

interface State {
  balance: number;
  point: number;
}

export class Comp extends Component<Props, State> {
  balanceService = new BalanceService();
  constructor(props: Props) {
    super(props);
    this.state = {
      balance: 0,
      point: 0,
    };
  };

  
  handleRollingToBalance = () => {
  
    this.balanceService.rolingToBalance(this.state.balance).then((data) => {
      if (data.status === "success") {
        confirmAlert({
          title: "콤프 전환",
          message: "콤프 전환을 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else if (data.status === "balance") {
        confirmAlert({
          title: "콤프 전환",
          message: "1.000원 이하는 콤프전환이 안됩니다 전환금액확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else if (data.status === "low_point") {
        confirmAlert({
          title: "콤프 전환",
          message: "보유하신 콤프금액이 부족합니다 .",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
        
      } else if (data.status === "pass") {
        confirmAlert({
          title: "콤프 전환",
          message: "환전 비밀번호를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "콤프",
          message:
            "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };

  handleChangeToPoint = () => {
    if (Number(this.state.point) <= 0) {
      confirmAlert({
        title: "금고머니",
        message: "금고머니를 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    confirmAlert({
      title: "금고머니",
      message: "금고머니를 출금하시겠습니까?",
      buttons: [
        {
          label: "확인",
          onClick: () => {
            this.balanceService
              .user_point_to_money(this.state.point)
              .then((data: any) => {
                if (data.status === "success") {
                  confirmAlert({
                    title: "금고머니",
                    message: "금고머니를 벨런스로 변경하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
                else {
                  confirmAlert({
                    title: "금고머니",
                    message: "보유금을 초과 하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
                
              });
          },
        },

        {
          label: "취소",
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    console.log(this.props.user);
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              id="cashin-modal"
              className="modal modal-theme fade in"
              role="dialog"
              style={{ display: "block", paddingRight: "17px" }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">콤프</h4>
                    <button
                      className="btn-close"
                      data-dismiss="modal"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <form
                      id="cashout-modal-form"
                      className="form-horizontal"
                      data-toggle="validator"
                      noValidate
                    >
                      <div className="form-group">
                        <label>보유금액</label>
                        <p
                          className="text-yellow form-details"
                          id="current_balance"
                        >
                          {ConverMoeny(this.props.user.balance)} ₩
                        </p>
                      </div>
                      <div className="form-group">
                        <label>콤프</label>
                        <p
                          className="text-yellow form-details"
                          id="current_balance"
                        >
                          {ConverMoeny(this.props.user.rolling)} ₩
                        </p>
                      </div>


                      <div className="form-group">
                        <label>콤프금액</label>
                        <input
                          className="form-control"
                          size={30}
                          value={this.state.balance}
                          onChange={(e) =>
                            this.setState({
                              balance: Number(e.target.value),
                            })
                          }
                          style={{ maxWidth: "40%" }}
                        />
                        <button
                          type="button"
                          id="btn-cashout-proceed"
                          className="btn btn-theme-blue"
                          onClick={this.handleRollingToBalance}
                          style={{ flexBasis: "33.333%", marginLeft: "auto" }}
                        >
                          콤프전환
                        </button>
                      </div>
             
                    </form>
                  </div>
                  <div className="modal-footer"></div>
                </div>
              </div>
            </div>

          </>
        )}
      </Popup>
    );
  }
}
