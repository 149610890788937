import React, { Component } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { isBrowser, isMobile } from "react-device-detect";
import { UserService } from "../service/user.service";
import { Main } from "./main";
import SoundPlays from "../SoundPlay";
import { NotePopup } from "./notepopup";
import { Reg } from "./reg";
import { I18nService } from "../service/i18n.service";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
}

interface Props {
  authenticated: boolean;
  session: string;
  tryLogin: (id: any, pw: any, lang: string) => any;
  tryLoginOut: () => any;

  SetCookie: (name: string, data: any) => any;
  GetCookie: (name: string) => any;
}

interface State {
  user: {
    id: string;

    in_balance: number;
    balance: number;
    rolling: number;
    
    point: number;
    bankname: string;
    banknum: string;
    bankowner: string;
  };
  helpCount: number;
  ID: string;
  PW: string;
  popupStatuses: string;
  lang: string;
}

async function sleep(ms: any) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export class Web extends Component<Props, State> {
  userService = new UserService();
  i18nService = new I18nService();

  constructor(props: Props) {
    super(props);
    this.state = {
      user: {
        id: "",
        in_balance: 0,
        balance: 0,
        rolling : 0,
        point: 0,
        bankname: "",
        banknum: "",
        bankowner: "",
      },
      helpCount: 0,
      ID: "",
      PW: "",
      popupStatuses: popupView.none,
      lang:
        this.props.GetCookie("lang") === undefined
          ? "ko"
          : this.props.GetCookie("lang"),
    };

    console.log("###################");
    console.log(this.props.GetCookie("lang"));
    console.log(this.state.lang);

    if (isBrowser) {
      setImmediate(async () => {
        try {
          for (;;) {
            this.updateUser();

            await sleep(30000);
          }
        } catch (ex) {}
      });
      // this.updateUser();
    }
  }

  componentDidMount() {}

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  updateUser = () => {
    this.userService.healthCheck().then((s: any) => {
      if (s.status === "success") {
        let user = {
          id: s.user.id,
          in_balance: s.user.in_balance,
          balance: s.user.balance,
          point: s.user.point,
          bankname: s.user.bankname,
          banknum: s.user.banknum,
          bankowner: s.user.bankowner,
          rolling: s.user.rolling,
          nick: s.user.nick,

        };

        if (s.helpCount > 0) {
          SoundPlays("helpCount");
        }
        if (s.noteCount > 0) {
          SoundPlays("helpCount");
        }

        if (JSON.stringify(user) !== JSON.stringify(this.state.user)) {
          this.setState({ user: user });
        }

        if (this.state.helpCount != s.helpCount) {
          this.setState({ helpCount: s.helpCount });
        }
      } else {
        window.location.hash = "/";
      }
    });
  };

  render() {
    const RenderPopup = () => {
      if (this.state.popupStatuses === popupView.reg) {
        return (
          <Reg handleClose={this.handleClosePopup} lang={this.state.lang}></Reg>
        );
      }

      return <div></div>;
    };

    if (!this.props.authenticated) {
      return (
        <>
          <div className="languages-container">
            <div
              id="kr-lang"
              className="language-item"
              onClick={() => {
                this.setState({ lang: "ko" });
              }}
            >
              <img
                src="/images/south-korea.png"
                alt="Korea"
                className="img-responsive"
              />
              <span>한국어</span>
            </div>

            <div
              id="en-lang"
              className="language-item"
              onClick={() => {
                this.setState({ lang: "en" });
              }}
            >
              <img
                src="/images/united-states.png"
                alt="English"
                className="img-responsive"
              />
              <span>English</span>
            </div>

            <div
              id="ch-lang"
              className="language-item"
              onClick={() => {
                this.setState({ lang: "ch" });
              }}
            >
              <img
                src="/images/china.png"
                alt="China"
                className="img-responsive"
              />
              <span>中文</span>
            </div>

            <div
              id="ja-lang"
              className="language-item"
              onClick={() => {
                this.setState({ lang: "ja" });
              }}
            >
              <img
                src="/images/japan.png"
                alt="Japan"
                className="img-responsive"
              />
              <span>日本語</span>
            </div>
          </div>
          <div className="all">
            <div className="bg-video">
              {/* <video autoplay muted loop preload>
	            <source src="http://lc-8899.com/videos/bg4.mp4" type="video/mp4"/>
	        </video> */}

              <video autoPlay loop>
                <source src="/images/bg4.mp4" type="video/mp4" />
              </video>
            </div>

            <div className="col-lg-12 padding-0">
              <div className="container-fluid">
                <div className="row">
                  <div className="logo_container center-block">
                    <div className="logo center-block">
                      {/* <h2 className="shadow">LUCKY</h2>
                      <h2 className="logo-text">LUCKY</h2> */}
                      <img
                        className="light_right"
                        src="/images/light_left.png"
                      />
                      <img
                        className="light_left"
                        src="/images/light_right.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid">
              <div className="row">
                <div className="col-xs-12 col-md-12">
                  <div className="wrapper">
                    <div className="login-box">
                      <div className="bg"></div>
                      <div className="form-group w-icon">
                        <img src="/images/user.png" className="icon" />
                        <input
                          type="text"
                          id="signin-username"
                          placeholder={this.i18nService.getMessage(
                            this.state.lang,
                            "username"
                          )}
                          className="form-control"
                          onChange={({ target: { value } }) =>
                            this.setState({ ID: value })
                          }
                        />
                        <span className="help-block"></span>
                      </div>

                      <div className="form-group w-icon">
                        <img
                          src="/images/password.png"
                          className="icon password"
                        />
                        <input
                          type="password"
                          id="signin-password"
                          placeholder={this.i18nService.getMessage(
                            this.state.lang,
                            "password"
                          )}
                          className="form-control"
                          onChange={({ target: { value } }) =>
                            this.setState({ PW: value })
                          }
                        />
                        <span className="help-block"></span>
                      </div>

                      <div className="form-group action-btn">
                        <button
                          id="btn-signin"
                          onClick={() => {
                            if (
                              this.state.ID == null ||
                              this.state.ID == "" ||
                              this.state.PW == "" ||
                              this.state.PW == null
                            ) {
                              return;
                            }
                            this.props.tryLogin(
                              this.state.ID,
                              this.state.PW,
                              this.state.lang
                            );
                          }}
                        >
                          {this.i18nService.getMessage(
                            this.state.lang,
                            "login"
                          )}
                        </button>
                        <button
                          id="join_btn"
                          data-toggle="modal"
                          data-target="#join-modal"
                          onClick={() => {
                            this.setState({ popupStatuses: popupView.reg }); //회원가입
                          }}
                        >
                          {this.i18nService.getMessage(
                            this.state.lang,
                            "signup"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 padding-0 footer-bg">
              <div className="container-fluid">
                <div className="row">
                  <img className="footer-img" src="/images/login_footer.png" />
                  <p className="footer text-center">
                    COPYRIGHT (C) 2018 블루칩 CASINO, LTD ALL RIGHTS RESERVED
                  </p>
                </div>
              </div>
            </div>
          </div>
          {RenderPopup()}
        </>
      );
    }

    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <Main
              activeHelp={this.state.helpCount > 0}
              authenticated={this.props.authenticated}
              session={this.props.session}
              user={this.state.user}
              tryLogin={this.props.tryLogin}
              tryLoginOut={() => {
                this.setState({
                  user: {
                    id: "",
                    in_balance: 0,
                    balance: 0,
                    point: 0,
                    rolling : 0,
                    bankname: "",
                    banknum: "",
                    bankowner: "",
                  },
                });
                this.props.tryLoginOut();
              }}
              updateUser={this.updateUser}
              lang={this.state.lang}
            />
          </Route>
        </Switch>

        {this.props.authenticated && (
          <NotePopup
            SetCookie={this.props.SetCookie}
            GetCookie={this.props.GetCookie}
          ></NotePopup>
        )} 

        <div id="helpCount" style={{ display: "none" }}>
          0
        </div>

        
      </Router>
    );
  }
}
