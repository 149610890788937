import React, { Component } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import { confirmAlert } from "react-confirm-alert"; // Import
import Typography from "@material-ui/core/Typography";
import { UserService } from "../service/user.service";
import { ConvertDate, HelpStatus } from "../utility/help";
import { I18nService } from "../service/i18n.service";

export enum helpView {
  none = "none",
  write = "write",
  view = "view",
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
}

const CustomTableCell = styled(TableCell)`
  background: linear-gradient(#2c2c2c 0%, #1c1c1c 100%);
  color: #dddddd;
  line-height: 44px;
  text-align: center;
  font-weight: bold;
  font-family: "nanumgothic", sans-serif;
`;

const CustomTableCell2 = styled(TableCell)`
  background: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
  line-height: 40px;
  padding: 0 0 0 15px;
  font-weight: bold;
`;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const service = new UserService();

function Row(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  let ref = "";
  if (row.ref != null) {
    ref = row.ref.contents;
  }

  const RenderRef = () => {
    if (ref != null && ref !== "") {
      return (
        <Box margin={1}>
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            color={"secondary"}
          >
            {/* 답변 */}
            this.i18nService.getBoardMessage(this.state.lang, "")
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: ref }}></div>
        </Box>
      );
    }
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root} key={row._id}>
        <CustomTableCell2>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              service.do_help_message_read(row._id);
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          onClick={() => {
            service.do_help_message_read(row._id);
            setOpen(!open);
          }}
        >
          {row.title}
        </CustomTableCell2>
        <CustomTableCell2 align="center">
          {ConvertDate(row.regDate)}
        </CustomTableCell2>
        <CustomTableCell2 align="center">
          {HelpStatus(row.status)}
        </CustomTableCell2>
      </TableRow>
      <TableRow>
        <CustomTableCell2
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div dangerouslySetInnerHTML={{ __html: row.contents }}></div>
            </Box>

            {RenderRef()}
          </Collapse>
        </CustomTableCell2>
      </TableRow>
    </React.Fragment>
  );
}

interface Props {
  handleClose: () => any;
  handleChange: (viewName: string) => any;
  lang: string;
}

interface State {
  helps: any;
  mode: string;

  title: string;
  contents: string;
  lang: string;
}

export class Help extends Component<Props, State> {
  userService = new UserService();
  i18nService = new I18nService();

  constructor(props: Props) {
    super(props);
    this.state = {
      helps: [],
      mode: helpView.view,
      title: "",
      contents: "",
      lang: this.props.lang,
    };
  }

  componentDidMount() {
    this.handleGetNotices();
  }

  handleGetNotices = () => {
    this.userService.get_help_list().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({ helps: data.helps });
      }
    });
  };

  handleSaveHelp = (title: string, contents: string) => {
    if (title == "") {
      confirmAlert({
        title: "고객센터",
        message: "타이틀을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.handleGetNotices();
            },
          },
        ],
      });
      return;
    }
    if (contents == "") {
      confirmAlert({
        title: "고객센터",
        message: "내용을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.handleGetNotices();
            },
          },
        ],
      });
      return;
    }

    this.userService.user_wirte_help(title, contents).then((date: any) => {
      if (date.status === "success") {
        confirmAlert({
          title: "고객센터",
          message: "게시물이 등록되었습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.handleGetNotices();
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "고객센터",
          message: "게시물이 등록되었습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });

    this.props.handleClose();
  };

  render() {
    let helps = this.state.helps;

    const RenderView = () => {
      if (this.state.mode !== helpView.view) {
        return <></>;
      }
      return (
        <>
          <div className="modal-body">
            <TableContainer component={Paper}>
              <Table
                size="small"
                aria-label="a dense table"
                style={{ marginBottom: "0px" }}
                className="table table-bordered table-striped"
              >
                <TableHead>
                  <TableRow>
                    <CustomTableCell align="center"></CustomTableCell>
                    <CustomTableCell align="center">
                      {/* 제 목 */}
                      {this.i18nService.getBoardMessage(
                        this.state.lang,
                        "title"
                      )}
                    </CustomTableCell>
                    <CustomTableCell align="center">
                      {/* 작성일 */}
                      {this.i18nService.getBoardMessage(
                        this.state.lang,
                        "date"
                      )}
                    </CustomTableCell>
                    <CustomTableCell align="center">
                      {/* 상태 */}
                      {this.i18nService.getBoardMessage(
                        this.state.lang,
                        "status"
                      )}
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {helps.map((row: any) => (
                    <Row key={row.name} row={row} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              id="btn-cashout-proceed"
              className="btn bg-metallic"
              onClick={() => {
                this.setState({ mode: helpView.write });
              }}
            >
              {/* 글쓰기 */}
              {this.i18nService.getBoardMessage(this.state.lang, "write")}
            </button>
          </div>
        </>
      );
    };

    const RenderWrite = () => {
      if (this.state.mode !== helpView.write) {
        return <></>;
      }

      return (
        <>
          <div className="modal-body">
            <table
              style={{ width: "100%", border: "0" }}
              cellSpacing="0"
              cellPadding="0"
              className="input1"
            >
              <tbody>
                <tr>
                  <td className="write_title">
                    {/* 제목 */}
                    {this.i18nService.getBoardMessage(this.state.lang, "title")}
                  </td>
                  <td className="write_basic">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) =>
                        this.setState({
                          title: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="write_title">
                    {/* 내용 */}
                    {this.i18nService.getBoardMessage(
                      this.state.lang,
                      "content"
                    )}
                  </td>
                  <td className="write_basic">
                    <textarea
                      className="form-control"
                      rows={10}
                      onChange={(e) =>
                        this.setState({
                          contents: e.target.value,
                        })
                      }
                      placeholder={this.i18nService.getBoardMessage(
                        this.state.lang,
                        "content1"
                      )} //"내용을 입력하세요"
                    ></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              id="btn-cashout-proceed"
              className="btn bg-metallic"
              onClick={() => {
                this.setState({ mode: helpView.view });
              }}
            >
              {/* 뒤로가기 */}
              {this.i18nService.getBoardMessage(this.state.lang, "back")}
            </button>
            <button
              type="button"
              id="btn-cashout-proceed"
              className="btn bg-metallic"
              style={{ marginLeft: "5px" }}
              onClick={() => {
                this.handleSaveHelp(this.state.title, this.state.contents);
              }}
            >
              {/* 저장하기 */}
              {this.i18nService.getBoardMessage(this.state.lang, "save")}
            </button>
          </div>
        </>
      );
    };
    return (
      <Popup
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              id="cashin-modal"
              className="modal modal-theme fade in"
              role="dialog"
              style={{ display: "block", paddingRight: "17px" }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">
                      {/* 고객센터 */}
                      {this.i18nService.getBoardMessage(
                        this.state.lang,
                        "help"
                      )}
                    </h4>
                    <button
                      className="btn-close"
                      data-dismiss="modal"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    ></button>
                  </div>

                  {RenderView()}
                  {RenderWrite()}
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
