import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";
import { BalanceService } from "../service/balance.service";
import { I18nService } from "../service/i18n.service";

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
  updateUser: () => any;
  lang: string;
}

interface State {
  balance: string;
  lang: string;
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
}

export class Deposit extends Component<Props, State> {
  balanceService = new BalanceService();
  i18nService = new I18nService();

  constructor(props: Props) {
    super(props);
    this.state = {
      balance: "",
      lang: this.props.lang,
    };

    console.log(this.props.user);
  }

  handleAsk = () => {
    this.balanceService.askToAccount().then((data: any) => {
      if (data.status === "success") {
        confirmAlert({
          title: this.i18nService.getDepositMessage(this.state.lang, "deposit"), //"입금",
          message: this.i18nService.getDepositMessage(
            this.state.lang,
            "message1"
          ), //"계좌문의에 성공하였습니다.",
          buttons: [
            {
              label: this.i18nService.getDepositMessage(
                this.state.lang,
                "confirm"
              ), //"확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: this.i18nService.getDepositMessage(this.state.lang, "deposit"), //"입금",
          message: this.i18nService.getDepositMessage(
            this.state.lang,
            "message2"
          ), //" 로그인후 이용해주세요.",
          buttons: [
            {
              label: this.i18nService.getDepositMessage(
                this.state.lang,
                "confirm"
              ), //"확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
      }
    });
  };

  handleDoDeposit = () => {
    if (Number(this.state.balance === "" ? 0 : this.state.balance) <= 0) {
      confirmAlert({
        title: this.i18nService.getDepositMessage(this.state.lang, "deposit"), //"입금",
        message: this.i18nService.getDepositMessage(
          this.state.lang,
          "message3"
        ), //"입금금액을 입력해주세요.",
        buttons: [
          {
            label: this.i18nService.getDepositMessage(
              this.state.lang,
              "confirm"
            ), //"확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (Number(this.state.balance === "" ? 0 : this.state.balance) < 30000) {
      confirmAlert({
        title: this.i18nService.getDepositMessage(this.state.lang, "deposit"), //"입금",
        message: this.i18nService.getDepositMessage(
          this.state.lang,
          "message4"
        ), //"최소 입금금액은 삼만원입니다.",
        buttons: [
          {
            label: this.i18nService.getDepositMessage(
              this.state.lang,
              "confirm"
            ), //"확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (
      Number(this.state.balance === "" ? 0 : this.state.balance) % 10000 >
      0
    ) {
      confirmAlert({
        title: this.i18nService.getDepositMessage(this.state.lang, "deposit"), //"입금",
        message: this.i18nService.getDepositMessage(
          this.state.lang,
          "message5"
        ), //"입금은 만원 단위로 가능합니다.",
        buttons: [
          {
            label: this.i18nService.getDepositMessage(
              this.state.lang,
              "confirm"
            ), //"확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.balanceService
      .applyUserDeposit(
        Number(this.state.balance === "" ? 0 : this.state.balance)
      )
      .then((data: any) => {
        console.log(data);
        if (data.status === "success") {
          confirmAlert({
            title: this.i18nService.getDepositMessage(
              this.state.lang,
              "deposit"
            ), //"입금",
            message: this.i18nService.getDepositMessage(
              this.state.lang,
              "message6"
            ), //"입금신청을 성공하였습니다.",
            buttons: [
              {
                label: this.i18nService.getDepositMessage(
                  this.state.lang,
                  "confirm"
                ), //"확인",
                onClick: () => {
                  this.props.handleClose();
                },
              },
            ],
          });
          return;
        } else if (data.status === "wait") {
          confirmAlert({
            title: this.i18nService.getDepositMessage(
              this.state.lang,
              "deposit"
            ), //"입금",
            message: this.i18nService.getDepositMessage(
              this.state.lang,
              "message7"
            ), //"대기중인 입금신청이 있습니다.",
            buttons: [
              {
                label: this.i18nService.getDepositMessage(
                  this.state.lang,
                  "confirm"
                ), //"확인",
                onClick: () => {},
              },
            ],
          });
          return;
        } else {
          confirmAlert({
            title: this.i18nService.getDepositMessage(
              this.state.lang,
              "deposit"
            ), //"입금",
            message: this.i18nService.getDepositMessage(
              this.state.lang,
              "message8"
            ), //"알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.",
            buttons: [
              {
                label: this.i18nService.getDepositMessage(
                  this.state.lang,
                  "confirm"
                ), //"확인",
                onClick: () => {},
              },
            ],
          });
        }
      });
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              id="cashin-modal"
              className="modal modal-theme fade in"
              role="dialog"
              style={{ display: "block", paddingRight: "17px" }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">
                      {/* 입금신청 */}
                      {this.i18nService.getDepositMessage(
                        this.state.lang,
                        "deposit"
                      )}
                    </h4>
                    <button
                      className="btn-close"
                      data-dismiss="modal"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="modal-description">
                      <div>
                        <img
                          src="/images/tips.png"
                          className="img-responsive"
                        />
                        <p>
                          {/* 주의사항 */}
                          {this.i18nService.getDepositMessage(
                            this.state.lang,
                            "precautions"
                          )}
                        </p>
                      </div>
                      <div>
                        <p>
                          {/* 고객님의 입금정보 내역을 꼭 확인하시기 바랍니다.
                          입금자명과 계좌번호가 다를결우 입금처리가 되지
                          않습니다. 출금하실때에도 입금계좌와 동일할 계좌로만
                          출금이 됩니다. */}
                          {this.i18nService.getDepositMessage(
                            this.state.lang,
                            "precautions1"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>
                        {/* 입금 은행명 */}
                        {this.i18nService.getDepositMessage(
                          this.state.lang,
                          "bank"
                        )}
                      </label>
                      <input
                        className="form-control"
                        size={30}
                        value={this.props.user.bankname}
                        readOnly
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        {/* 입금자명 */}
                        {this.i18nService.getDepositMessage(
                          this.state.lang,
                          "depositor"
                        )}
                      </label>
                      <input
                        className="form-control"
                        size={30}
                        value={this.props.user.bankowner}
                        readOnly
                      />
                    </div>
                    <div className="modal-description">
                      <div>
                        <img
                          src="/images/tips.png"
                          className="img-responsive"
                        />
                        <p>
                          {/* 주의사항 */}
                          {this.i18nService.getDepositMessage(
                            this.state.lang,
                            "precautions"
                          )}
                        </p>
                      </div>
                      <div>
                        <p>
                          {/* 입금전 반드시 계좌문의를 문의하시고, 입금하시기
                          바랍니다. 계좌가 수시로 변경될수 있으니 문의없이
                          입금하신 회원님의 입금건에 대해서는 절대 책임지지
                          않습니다. */}
                          {this.i18nService.getDepositMessage(
                            this.state.lang,
                            "precautions2"
                          )}
                        </p>
                      </div>
                    </div>
                    <form
                      id="cashin-modal-form"
                      className="form-horizontal"
                      data-toggle="validator"
                      noValidate
                    >
                      <div className="form-group">
                        <label>
                          {/* 입금할 금액 */}
                          {this.i18nService.getDepositMessage(
                            this.state.lang,
                            "amount"
                          )}
                        </label>
                        <input
                          className="form-control"
                          size={30}
                          id="deposit_amount"
                          value={this.state.balance}
                          onClick={(e) =>
                            this.setState({
                              balance: "",
                            })
                          }
                          onChange={(e) =>
                            this.setState({
                              balance: e.target.value,
                            })
                          }
                        />
                        <span className="help-block"></span>
                      </div>
                    </form>

                    <div className="btngrp">
                      <button
                        id="cashin-input1"
                        className="btn btn-theme-default btn-sm"
                        onClick={() => {
                          this.setState({
                            balance: String(Number(this.state.balance) + 10000),
                          });
                        }}
                      >
                        {" "}
                        {/* 1만 */}
                        {this.i18nService.getDepositMessage(
                          this.state.lang,
                          "10k"
                        )}
                      </button>
                      <button
                        id="cashin-input2"
                        className="btn btn-theme-default btn-sm"
                        onClick={() => {
                          this.setState({
                            balance: String(Number(this.state.balance) + 50000),
                          });
                        }}
                        style={{ marginLeft: "5px" }}
                      >
                        {" "}
                        {/* 5만 */}
                        {this.i18nService.getDepositMessage(
                          this.state.lang,
                          "50k"
                        )}
                      </button>
                      <button
                        id="cashin-input3"
                        className="btn btn-theme-default btn-sm"
                        onClick={() => {
                          this.setState({
                            balance: String(
                              Number(this.state.balance) + 100000
                            ),
                          });
                        }}
                        style={{ marginLeft: "5px" }}
                      >
                        {" "}
                        {/* 10만 */}
                        {this.i18nService.getDepositMessage(
                          this.state.lang,
                          "100k"
                        )}
                      </button>
                      <button
                        id="cashin-input4"
                        className="btn btn-theme-default btn-sm"
                        onClick={() => {
                          this.setState({
                            balance: String(
                              Number(this.state.balance) + 500000
                            ),
                          });
                        }}
                        style={{ marginLeft: "5px" }}
                      >
                        {" "}
                        {/* 50만 */}
                        {this.i18nService.getDepositMessage(
                          this.state.lang,
                          "500k"
                        )}
                      </button>
                      <button
                        id="cashin-input5"
                        className="btn btn-theme-default btn-sm"
                        onClick={() => {
                          this.setState({
                            balance: String(
                              Number(this.state.balance) + 1000000
                            ),
                          });
                        }}
                        style={{ marginLeft: "5px" }}
                      >
                        {" "}
                        {/* 100만 */}
                        {this.i18nService.getDepositMessage(
                          this.state.lang,
                          "1000k"
                        )}
                      </button>
                      <button
                        id="cashin-input-clear"
                        className="btn btn-theme-dark-blue btn-sm"
                        onClick={() => {
                          this.setState({
                            balance: "",
                          });
                        }}
                        style={{ marginLeft: "5px" }}
                      >
                        {" "}
                        {/* 정정 */}
                        {this.i18nService.getDepositMessage(
                          this.state.lang,
                          "clear"
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      id="btn-cashin-proceed"
                      className="btn bg-metallic"
                      onClick={this.handleDoDeposit}
                    >
                      {/* 신청하기 */}
                      {this.i18nService.getDepositMessage(
                        this.state.lang,
                        "apply"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
