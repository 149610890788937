import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { UserService } from "../service/user.service";
import { I18nService } from "../service/i18n.service";

interface Props {
  handleClose: () => any;
  lang: string;
}

interface State {
  id: string;
  nick: string;
  
  pass: string;
  pass_check: string;
  phone1: string;
  phone2: string;
  phone3: string;
  phone: string;

  bankname: string;
  banknumber: string;
  bankowner: string;
  exchange_pw: string;
  code: string;
  lang: string;
}

export class Reg extends Component<Props, State> {
  userService = new UserService();
  i18nService = new I18nService();

  constructor(props: Props) {
    super(props);

    this.state = {
      id: "",
      nick: "",
      pass: "",
      pass_check: "",
      phone1: "",
      phone2: "",
      phone3: "",
      phone: "",

      bankname: "",
      banknumber: "",
      bankowner: "",
      exchange_pw: "",
      code: "",
      lang: this.props.lang,
    };
  }

  handleCheck = () => {
    if (
      this.state.id === undefined ||
      this.state.id.length <= 3 ||
      20 <= this.state.id.length
    ) {
      confirmAlert({
        title: this.i18nService.getMessage(this.state.lang, "signup"), //"회원 가입",
        message: this.i18nService.getMessage(this.state.lang, "message1"), //"아이디를 입력 또는 3자리 이상 20자리 이하로 작성해주세요",
        buttons: [
          {
            label: this.i18nService.getMessage(this.state.lang, "confirm"), //"확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.userService.regCheckID(this.state.id).then((s: any) => {
      if (s.status === "success") {
        confirmAlert({
          title: this.i18nService.getMessage(this.state.lang, "signup"), //"회원 가입",
          message: this.i18nService.getMessage(this.state.lang, "message2"), //"사용 할수있는 아이디 입니다.",
          buttons: [
            {
              label: this.i18nService.getMessage(this.state.lang, "confirm"), //"확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: this.i18nService.getMessage(this.state.lang, "signup"), //"회원 가입",
          message: this.i18nService.getMessage(this.state.lang, "message3"), //"사용 불가능한 아이디 입니다.",
          buttons: [
            {
              label: this.i18nService.getMessage(this.state.lang, "confirm"), //"확인",
              onClick: () => {
                this.setState({ id: "" });
              },
            },
          ],
        });
        return;
      }
    });
  };

  handleReg = () => {
    if (
      this.state.id === undefined ||
      this.state.id.length <= 3 ||
      20 <= this.state.id.length
    ) {
      confirmAlert({
        title: this.i18nService.getMessage(this.state.lang, "signup"), //"회원 가입",
        message: this.i18nService.getMessage(this.state.lang, "message4"), //"아이디를 입력 또는 3자리 이상 20자리 이하로 작성해주세요",
        buttons: [
          {
            label: this.i18nService.getMessage(this.state.lang, "confirm"), //"확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (
      this.state.nick === undefined ||
      this.state.nick.length < 2 ||
      20 <= this.state.nick.length
    ) {
      confirmAlert({
        title: "닉네임", //"회원 가입",
        message: "닉네임를 입력 또는 2자리 이상 20자리 이하로 작성해주세요", //"아이디를 입력 또는 3자리 이상 20자리 이하로 작성해주세요",
        buttons: [
          {
            label: this.i18nService.getMessage(this.state.lang, "confirm"), //"확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    

    if (
      this.state.pass == null ||
      this.state.pass_check == null ||
      this.state.pass !== this.state.pass_check
    ) {
      confirmAlert({
        title: this.i18nService.getMessage(this.state.lang, "signup"), //"회원 가입",
        message: this.i18nService.getMessage(this.state.lang, "message5"), //"비밀번호를 확인해주세요",
        buttons: [
          {
            label: this.i18nService.getMessage(this.state.lang, "confirm"), //"확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    let phone = this.state.phone;
    if (phone == null || phone.length <= 10) {
      confirmAlert({
        title: this.i18nService.getMessage(this.state.lang, "signup"), //"회원 가입",
        message: this.i18nService.getMessage(this.state.lang, "message6"), //"휴대폰 번호를 확인해주세요",
        buttons: [
          {
            label: this.i18nService.getMessage(this.state.lang, "confirm"), //"확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (this.state.bankname == null) {
      confirmAlert({
        title: this.i18nService.getMessage(this.state.lang, "signup"), //"회원 가입",
        message: this.i18nService.getMessage(this.state.lang, "message7"), //"은행명을 확인해주세요",
        buttons: [
          {
            label: this.i18nService.getMessage(this.state.lang, "confirm"), //"확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (this.state.banknumber == null) {
      confirmAlert({
        title: this.i18nService.getMessage(this.state.lang, "signup"), //"회원 가입",
        message: this.i18nService.getMessage(this.state.lang, "message8"), //"계좌 번호를  확인해주세요",
        buttons: [
          {
            label: this.i18nService.getMessage(this.state.lang, "confirm"), //"확인",
            onClick: () => {},
          },
        ],
      });

      return;
    }

    if (this.state.bankowner == null) {
      confirmAlert({
        title: this.i18nService.getMessage(this.state.lang, "signup"), //"회원 가입",
        message: this.i18nService.getMessage(this.state.lang, "message9"), //"예금주 이름을 확인해주세요",
        buttons: [
          {
            label: this.i18nService.getMessage(this.state.lang, "confirm"), //"확인",
            onClick: () => {},
          },
        ],
      });

      return;
    }

    this.userService
      .regUser({
        id: this.state.id.trim(),
        nick: this.state.nick.trim(),
        
        pw: this.state.pass,
        pass_check: this.state.pass_check,
        phone: phone,
        bankname: this.state.bankname,
        banknumber: this.state.banknumber,
        bankowner: this.state.bankowner,
        exchange_pw: this.state.bankowner,
        code: this.state.code,
      })
      .then((data: any) => {
        if (data.status === "alread_have_user") {
          confirmAlert({
            title: "회원 가입",
            message: "생성할수 없는 유저아이디 입니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {},
              },
            ],
          });
          return;
        } else if (data.status === "cant_find_code") {
          confirmAlert({
            title: "회원 가입",
            message: "회원 가입코드를 확인해주세요.",
            buttons: [
              {
                label: "확인",
                onClick: () => {},
              },
            ],
          });

          return;
        } else {
          confirmAlert({
            title: "회원 가입",
            message: "회원 가입에 성공하였습니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });

          return;
        }
      });
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 1,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              id="join-modal"
              className="modal modal-theme fade in"
              role="dialog"
              style={{ display: "block", paddingRight: "17px" }}
            >
              <div className="modal-dialog modal-md customer-modal">
                <div className="modal-content">
                  <div className="modal-header text-center">
                    <h4 className="modal-title">Join</h4>
                    <button
                      className="btn-close"
                      data-dismiss="modal"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="form-group">
                      <label>
                        {this.i18nService.getMessage(
                          this.state.lang,
                          "username"
                        )}
                      </label>
                      <input
                        type="text"
                        name="id"
                        className="form-control"
                        minLength={3}
                        maxLength={15}
                        style={{ marginRight: "5px" }}
                        value={this.state.id}
                        onChange={(e: any) => {
                          this.setState({ id: e.target.value });
                        }}
                      />
                      <span className="help-block"></span>
                    </div>
                    <div className="form-group">
                      <label>닉네임</label>
                      <input
                        type="text"
                        name="id"
                        className="form-control"
                        minLength={3}
                        maxLength={15}
                        style={{ marginRight: "5px" }}
                        value={this.state.nick}
                        onChange={(e: any) => {
                          this.setState({ nick: e.target.value });
                        }}
                      />
                      <span className="help-block"></span>
                    </div>
                    
                    <div className="form-group">
                      <label>
                        {this.i18nService.getMessage(
                          this.state.lang,
                          "password"
                        )}
                      </label>
                      <input
                        type="password"
                        name="pass"
                        className="form-control"
                        maxLength={15}
                        value={this.state.pass}
                        onChange={(e: any) => {
                          this.setState({ pass: e.target.value });
                        }}
                      />
                      <span className="help-block"></span>
                    </div>
                    <div className="form-group">
                      <label>
                        {this.i18nService.getMessage(
                          this.state.lang,
                          "confirm_password"
                        )}
                      </label>
                      <input
                        type="password"
                        name="pass2"
                        className="form-control"
                        minLength={3}
                        maxLength={15}
                        value={this.state.pass_check}
                        onChange={(e: any) => {
                          this.setState({ pass_check: e.target.value });
                        }}
                      />
                      <span className="help-block"></span>
                    </div>
                    <div className="form-group">
                      <label>
                        {/* 핸드폰번호 */}
                        {this.i18nService.getMessage(this.state.lang, "mobile")}
                      </label>
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        minLength={1}
                        maxLength={11}
                        placeholder=""
                        value={this.state.phone}
                        onChange={(e: any) => {
                          this.setState({ phone: e.target.value });
                        }}
                      />
                      <span className="help-block">
                        {this.i18nService.getMessage(
                          this.state.lang,
                          "signup_message"
                        )}
                      </span>
                    </div>
                    <div className="form-group">
                      <label>
                        {/* 은행 */}
                        {this.i18nService.getMessage(
                          this.state.lang,
                          "bank_name"
                        )}
                      </label>
                      <input
                        type="text"
                        name="bankname"
                        className="form-control"
                        minLength={1}
                        maxLength={20}
                        value={this.state.bankname}
                        onChange={(e: any) => {
                          this.setState({ bankname: e.target.value });
                        }}
                      />
                      <span className="help-block"></span>
                    </div>
                    <div className="form-group">
                      <label>
                        {/* 예금주 */}
                        {this.i18nService.getMessage(
                          this.state.lang,
                          "bank_account_name"
                        )}
                      </label>
                      <input
                        type="text"
                        name="ownname"
                        className="form-control"
                        minLength={1}
                        maxLength={20}
                        value={this.state.bankowner}
                        onChange={(e: any) => {
                          this.setState({ bankowner: e.target.value });
                        }}
                      />
                      <span className="help-block"></span>
                    </div>
                    <div className="form-group">
                      <label>
                        {/* 계좌번호 */}
                        {this.i18nService.getMessage(
                          this.state.lang,
                          "bank_account_no"
                        )}
                      </label>
                      <input
                        type="text"
                        name="bankno"
                        className="form-control"
                        minLength={1}
                        maxLength={20}
                        value={this.state.banknumber}
                        onChange={(e: any) => {
                          this.setState({ banknumber: e.target.value });
                        }}
                      />
                      <span className="help-block"></span>
                    </div>
                    <div className="form-group">
                      <label>
                        {/* 환전 비밀번호 */}
                        {this.i18nService.getMessage(
                          this.state.lang,
                          "cashout_pin"
                        )}
                      </label>
                      <input
                        type="text"
                        name="recommender"
                        className="form-control"
                        minLength={1}
                        maxLength={15}
                        placeholder=""
                        value={this.state.exchange_pw}
                        onChange={(e: any) => {
                          this.setState({
                            exchange_pw: e.target.value,
                          });
                        }}
                      />
                      <span className="help-block"></span>
                    </div>
                    <div className="form-group">
                      <label>
                        {/* 추천인코드 */}
                        {this.i18nService.getMessage(
                          this.state.lang,
                          "referral_code"
                        )}
                      </label>
                      <input
                        type="text"
                        name="recommender"
                        className="form-control"
                        minLength={1}
                        maxLength={15}
                        placeholder=""
                        value={this.state.code}
                        onChange={(e: any) => {
                          this.setState({ code: e.target.value });
                        }}
                      />
                      <span className="help-block"></span>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      id="btn-signup"
                      className="btn btn-theme-default"
                      onClick={() => {
                        this.handleReg();
                      }}
                    >
                      {" "}
                      {/* 회원가입 */}
                      {this.i18nService.getMessage(this.state.lang, "signup")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
