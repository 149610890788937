import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";
import { UserService } from "../service/user.service";
import { SlotService } from "../service/slot.service";
import { BalanceService } from "../service/balance.service";

import { Deposit } from "./deposit";
import { Withdraw } from "./withdraw";
import { Help } from "./help";
import { User } from "./user";
import { Notice } from "./notice";
import { Comp } from "./comp";

import { Reg } from "./reg";
import { Login } from "./login";
import { Point } from "./point";
import { Note } from "./note";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { I18nService } from "../service/i18n.service";

export enum popupView {
  
  comp = "comp",
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
}

async function sleep(ms: any) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

interface Props {
  user: any;
  activeHelp: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any, lang: string) => any;
  tryLoginOut: () => any;
  updateUser: () => any;
  lang: string;
}

interface State {
  ID: string;
  PW: string;
  balance: number;
  postCount: number;
  noteCount: number;
  note: any;
  isOpen: boolean;
  isOpen2: boolean;
  popupStatuses: string;
  expanded: string;
  notes: any;
  lang: string;
}

export class Header extends Component<Props, State> {
  userService = new UserService();
  slotService = new SlotService();
  i18nService = new I18nService();
  balanceService = new BalanceService();

  constructor(props: Props) {
    super(props);
    this.state = {
      balance: 0,
      ID: "",
      PW: "",
      postCount: 0,
      note: [],
      notes: [],
      isOpen: false,
      isOpen2: false,
      popupStatuses: popupView.none,
      noteCount: 0,
      expanded: "",
      lang: this.props.lang,
    };

    this.handleUpdateNote();

    setImmediate(async () => {
      try {
        for (;;) {
          this.handleUpdateNote();

          await sleep(10000);
        }
      } catch (ex) {}
    });
    // this.updateUser();
  }

  componentDidMount() {
    this.handleUpdateInBalance();
  }

  
  handleAsk = () => {
    this.balanceService.askToAccount().then((data) => {
      if (data.status === "success") {
        confirmAlert({
          title: "입금",
          message: "계좌문의에 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
      } else {
        confirmAlert({

          title: "입금",
          message: " 로그인후 이용해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },
          ],
        });
      }
    });
  };

  handleUpdateInBalance = () => {
    this.slotService.get_in_game_balance().then((data: any) => {
      if (data.status === "success") {
        this.setState({
          balance: data.balance ?? 0,
        });
        this.props.user.balance = data.balance ?? 0;
      } else {
      }
    });
  };

  handleUpdateNote = () => {
    this.userService.get_user_note().then((data: any) => {
      if (data.status === "success") {
        console.log(data);
        this.setState({
          postCount: data.count,
          note: data.note,
          isOpen: data.count > 0 ? true : false,
        });
      } else if (this.props.authenticated) {
        this.props.tryLoginOut();
      }
    });
  };

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  handleReadNote = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_read_note(id).then((data: any) => {
        if (data.status === "success") {
          let note = this.state.note.find((read: any) => read._id === id);
          if (note != null) {
            note.isRead = "y";
            this.setState({
              note: this.state.note,
            });
          }
        }
      });
    }
  };

  do_del_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_del_all_note().then((data: any) => {
        window.location.reload();
      });
    }
  };

  do_read_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_read_all_note().then((data: any) => {
        window.location.reload();
      });
    }
  };

  handleDel = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_del_note(id).then((data: any) => {
        if (data.status === "success") {
          this.setState({
            notes: data.note,
          });
          this.handleUpdateNote();
        }
      });
    }
  };

  changePopup = (value: any) => {
    this.setState({ popupStatuses: value });
  };

  render() {
    const user = this.props.user;
    const authenticated = this.props.authenticated;

    const RenderPopup = () => {
      if (this.state.popupStatuses === popupView.deposit) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Deposit
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            user={this.props.user}
            updateUser={this.props.updateUser}
            lang={this.state.lang}
          ></Deposit>
        );
      }

      if (this.state.popupStatuses === popupView.withdraw) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Withdraw
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
            lang={this.state.lang}
          ></Withdraw>
        );
      }
      if (this.state.popupStatuses === popupView.notice) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Notice
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            lang={this.state.lang}
          ></Notice>
        );
      }

      if (this.state.popupStatuses === popupView.note) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Note
            handleClose={this.handleClosePopup}
            handleDel={this.handleDel}
            handleReadNote={this.handleReadNote}
            note={this.state.note}
            lang={this.state.lang}
          ></Note>
        );
      }

      if (this.state.popupStatuses === popupView.point) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Point
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Point>
        );
      }

      // if (this.state.popupStatuses === popupView.reg) {
      //   return <Reg handleClose={this.handleClosePopup}></Reg>;
      // }

      // if (this.state.popupStatuses === popupView.login) {
      //   return (
      //     <Login
      //       handleClose={this.handleClosePopup}
      //       tryLogin={this.props.tryLogin}
      //       changePopup={this.changePopup}
      //     ></Login>
      //   );
      // }

      if (this.state.popupStatuses === popupView.help) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Help
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            lang={this.state.lang}
          ></Help>
        );
      }

      if (this.state.popupStatuses === popupView.comp) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Comp
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Comp>
        );
      }

      
      if (this.state.popupStatuses === popupView.user) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <User
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            user={this.props.user}
            lang={this.state.lang}
          ></User>
        );
      }

      return <div></div>;
    };

    const RendePopup = () => {
      // if (0 < this.state.postCount) {
      //   this.setState({ popupStatuses: popupView.note });
      // }

      // this.setState({ popupStatuses: popupView.note });

      return <div></div>;
    };

    return (
      <>
        <div className="col-lg-12 padding-0">
          <div className="container-fluid">
            <div className="row">
              <div className="top_navbar">
                <button
                  id="menu_btn"
                  onClick={() => {
                    this.setState({ isOpen2: true });
                  }}
                  style={{ display: this.state.isOpen2 ? "none" : "block", }}
                >
                  <span className="glyphicon glyphicon-menu-hamburger"></span>
                </button>
                <button
                  id="menu_btn_active"
                  onClick={() => {
                    this.setState({ isOpen2: false });
                  }}
                  style={{ display: this.state.isOpen2 ? "block" : "none" }}
                >
                  <span
                    id="mba_span1"
                    className="glyphicon glyphicon-triangle-left"
                  ></span>
                  <span
                    id="mba_span2"
                    className="glyphicon glyphicon-menu-hamburger"
                  ></span>
                </button>
                <a href="javascript:void(0);" id="chat-box">
                  <img src="/images/comment.png" className="img-responsive" />
                </a>

                <div className="logo-container">
                  <div className="logo center-block" style={{backgroundSize : '40px', left : '42%'}}>
                    {/* <h2 className="shadow">LUCKY</h2>
                    <h2 className="logo-text">LUCKY</h2> */}
                    {/* <img className="light_right" src="/images/light_left.png" />
                    <img className="light_left" src="/images/light_right.png" /> */}
                  </div>
                </div>

                <div className="navbar">
                  <div className="navbar_container center-block">
                    <div className="navbar_right">
                      <a
                        id="request-cashin"
                        href="javascript:;"
                        onClick={() => {
                          this.setState({ popupStatuses: popupView.deposit }); //입금신청
                        }}
                      >
                        {/* 입금신청 */}
                        {this.i18nService.getMainMessage(
                          this.state.lang,
                          "deposite"
                        )}
                      </a>
                      <a
                        id="request-cashout"
                        href="javascript:;"
                        onClick={() => {
                          this.setState({ popupStatuses: popupView.withdraw }); //출금신청
                        }}
                      >
                        {/* 출금신청 */}
                        {this.i18nService.getMainMessage(
                          this.state.lang,
                          "withdraw"
                        )}
                      </a>
                      <a
                        id="bet-history"
                        href="javascript:;"
                        onClick={() => {
                          this.setState({ popupStatuses: popupView.help }); //고객센터
                        }}
                      >
                        {/* 고객센터 */}
                        {this.i18nService.getMainMessage(
                          this.state.lang,
                          "help"
                        )}
                      </a>
                      <a
                        id="request-bank"
                        href="javascript:;"
                        onClick={() => this.handleAsk()}
                      >
                        {/* 공지사항 */}
                        {this.i18nService.getMainMessage(
                          this.state.lang,
                          "notice"
                        )}
                      </a>
                      <a
                        id="request-bank"
                        href="javascript:;"
                        onClick={() =>      this.setState({ popupStatuses: popupView.point })}
                      >
                        금고
                      </a>

                      <a
                        id="request-bank"
                        href="javascript:;"
                        onClick={() =>      this.setState({ popupStatuses: popupView.comp })}
                      >
                        콤프
                      </a>
                      <a
                        id="request-bank"
                        href="javascript:;"
                        onClick={() => {
                          this.setState({ popupStatuses: popupView.note }); //쪽지
                        }}
                      >
                        {/* 쪽지 */}
                        {this.i18nService.getMainMessage(
                          this.state.lang,
                          "message"
                        )}
                      </a>
                      <a
                        id="request-bank"
                        href="javascript:;"
                        onClick={() => {
                          this.setState({ popupStatuses: popupView.user }); //마이페이지
                        }}
                      >
                        {/* 마이페이지 */}
                        {this.i18nService.getMainMessage(
                          this.state.lang,
                          "mypage"
                        )}
                      </a>
                    </div>
                  </div>
                </div>

                <div className="menu_news">
                  <img src="/images/mic.png" />
                  <OwlCarousel
                    items={1}
                    className="owl-theme"
                    loop={true}
                    margin={0}
                    dots={false}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                  >
                    <div>
                      <p className="news_yellow">
                        ★회원님의 보안을 위하여 5회 이상 배팅을 하지 않으실 경우
                        로비로 자동 이동됩니다.★
                      </p>
                    </div>
                    <div>
                      <p className="news_yellow">
                        ★배팅마감과 동시에 확인 버튼이 눌러졌을경우, 빠른
                        진행으로 인해 정상적으로 신호값을 받지 못했을경우,
                        인터넷사정으로 인해 배팅실패 문구가 떳을 경우와같이
                        배팅내역이 존재하지 않는 모든 회차는 고객님의 승패와
                        상관없이 무효처리됩니다.★
                      </p>
                    </div>
                    <div>
                      <p className="news_yellow">
                        ★필독!!계좌변경되었습니다! 계좌문의후 입금바랍니다. 꼭!!
                        계좌문의후 확인하시고입금바랍니다.!!★
                      </p>
                    </div>
                    <div>
                      <p className="news_yellow">
                        ★오리엔탈 카지노 일일 점검시간이 매일 오전 6시에서 7시로
                        변경 되였습니다.★
                      </p>
                    </div>
                    <div>
                      <p className="news_yellow">
                        ★모든 게임 진행 적중 결과는 베팅결과로 처리되는 걸
                        원칙으로 합니다. 이 점 필히 참고 하시고 이용해 주세요!★
                      </p>
                    </div>
                    <div>
                      <p className="news_yellow">
                        ★카가얀88의 배팅시간은 25초이며, 50회차 까지만 배팅
                        가능합니다. 영상과 관계없이 결과값이 올라오는걸로
                        당첨금이 지급되오니, 이점 꼭 참고 하시기 바랍니다.★
                      </p>
                    </div>
                    <div>
                      <p className="news_yellow">
                        ★테이블영상 우측 타이머는 실제 배팅시간과 동일하지
                        않습니다.참고만 하시고 배팅은 신속히 해 주시기
                        바랍니다.★
                      </p>
                    </div>
                    <div>
                      <p className="news_yellow">
                        ★본사 규정상 전액 환전만 가능하며, 출금 요청시 만원
                        단위로 출금신청 해주시기 바랍니다.★
                      </p>
                    </div>
                    <div>
                      <p className="news_yellow">
                        ★회원님의 보안을 위하여 5회 이상 배팅을 하지 않으실 경우
                        로비로 자동 이동됩니다.★
                      </p>
                    </div>
                    <div>
                      <p className="news_yellow">
                        ★배팅마감과 동시에 확인 버튼이 눌러졌을경우, 빠른
                        진행으로 인해 정상적으로 신호값을 받지 못했을경우,
                        인터넷사정으로 인해 배팅실패 문구가 떳을 경우와같이
                        배팅내역이 존재하지 않는 모든 회차는 고객님의 승패와
                        상관없이 무효처리됩니다.★
                      </p>
                    </div>
                    <div>
                      <p className="news_yellow">
                        ★필독!!계좌변경되었습니다! 계좌문의후 입금바랍니다. 꼭!!
                        계좌문의후 확인하시고입금바랍니다.!!★
                      </p>
                    </div>
                    <div>
                      <p className="news_yellow">
                        ★오리엔탈 카지노 일일 점검시간이 매일 오전 6시에서 7시로
                        변경 되였습니다.★
                      </p>
                    </div>
                    <div>
                      <p className="news_yellow">
                        ★모든 게임 진행 적중 결과는 베팅결과로 처리되는 걸
                        원칙으로 합니다. 이 점 필히 참고 하시고 이용해 주세요!★
                      </p>
                    </div>
                    <div>
                      <p className="news_yellow">
                        ★카가얀88의 배팅시간은 25초이며, 50회차 까지만 배팅
                        가능합니다. 영상과 관계없이 결과값이 올라오는걸로
                        당첨금이 지급되오니, 이점 꼭 참고 하시기 바랍니다.★
                      </p>
                    </div>
                    <div>
                      <p className="news_yellow">
                        ★테이블영상 우측 타이머는 실제 배팅시간과 동일하지
                        않습니다.참고만 하시고 배팅은 신속히 해 주시기
                        바랍니다.★
                      </p>
                    </div>
                    <div>
                      <p className="news_yellow">
                        ★본사 규정상 전액 환전만 가능하며, 출금 요청시 만원
                        단위로 출금신청 해주시기 바랍니다.★
                      </p>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={this.state.isOpen2 ? "sidenav open" : "sidenav"}>
          <div className="header2">
            <div className="header">
              <div className="join text-center">
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.props.tryLoginOut();
                  }}
                >
                  <input
                    className="center-block"
                    id="btn-logout"
                    type="image"
                    src="/images/key.png"
                  />
                </a>
                <p>
                  {/* 로그아웃 */}
                  {this.i18nService.getMainMessage(this.state.lang, "logout")}
                </p>
              </div>
              <div className="login text-center">
                <input
                  className="center-block"
                  id="btn-profile-settings"
                  type="image"
                  src="/images/star.png"
                  onClick={() => {
                    this.setState({ isOpen2: false });
                    this.setState({ popupStatuses: popupView.user }); //마이페이지
                  }}
                />
                <p>
                  {/* 마이페이지 */}
                  {this.i18nService.getMainMessage(this.state.lang, "mypage")}
                </p>
              </div>
            </div>
            <div className="sidenav_header2_row">
              <div className="left_whole">
                <img src="/images/user.png" />
                <p>
                  <span className="bold" id="btn-profile-settings">
                    {this.props.user.id}
                  </span>{" "}
                  {/* 님 */}
                  {this.i18nService.getMainMessage(this.state.lang, "sir")}
                </p>
              </div>
            </div>
            <div className="sidenav_header2_row">
              <div className="left_whole">
                <img
                  src="/images/money_coin.png"
                  style={{ marginRight: "6%" }}
                />
                <span className="text-bronze-light bold">
                  <span id="profile-credits">
                    {ConverMoeny(this.state.balance)}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div className="title">
            <p className="text-yellow">
              {/* 메인메뉴 */}
              {this.i18nService.getMainMessage(this.state.lang, "mainmenu")}
            </p>
          </div>
          <div className="choice side_cashin" id="sidebar_choice2">
            <a
              href="javascript:;"
              onClick={() => {
                this.setState({ isOpen2: false });
                this.setState({ popupStatuses: popupView.deposit }); //입금신청
              }}
            >
              <div className="left">
                <img id="choices_lefticon" src="/images/deposit.png" />
              </div>
              <div className="right">
                <p>
                  {/* 입금신청 */}
                  {this.i18nService.getMainMessage(this.state.lang, "deposite")}
                </p>
              </div>
            </a>
          </div>
          <div className="choice side_cashout" id="sidebar_choice3">
            <a
              href="javascript:;"
              onClick={() => {
                this.setState({ isOpen2: false });
                this.setState({ popupStatuses: popupView.withdraw }); //출금신청
              }}
            >
              <div className="left">
                <img id="choices_lefticon" src="/images/withdraw.png" />
              </div>
              <div className="right">
                <p>
                  {/* 출금신청 */}
                  {this.i18nService.getMainMessage(this.state.lang, "withdraw")}
                </p>
              </div>
            </a>
          </div>
          <div className="choice bethistory" id="sidebar_choice5">
            <a
              href="javascript:;"
              onClick={() => {
                this.setState({ isOpen2: false });
                this.setState({ popupStatuses: popupView.help }); //고객센터
              }}
            >
              <div className="left">
                <img id="choices_lefticon" src="/images/refresh.png" />
              </div>
              <div className="right">
                <p>
                  {/* 고객센터 */}
                  {this.i18nService.getMainMessage(this.state.lang, "help")}
                </p>
              </div>
            </a>
          </div>
          <div className="choice side_bank" id="sidebar_choice6">
            <a
              href="javascript:;"
              onClick={() => {
                this.setState({ isOpen2: false });
                this.setState({ popupStatuses: popupView.notice }); //공지사항
              }}
            >
              <div className="left">
                <img id="choices_lefticon" src="/images/rocket.png" />
              </div>
              <div className="right">
                <p>
                  {/* 공지사항 */}
                  {this.i18nService.getMainMessage(this.state.lang, "notice")}
                </p>
              </div>
            </a>
          </div>
          <div className="choice side_bank" id="sidebar_choice6">
            <a
              href="javascript:;"
              onClick={() => {
                this.setState({ isOpen2: false });
                this.setState({ popupStatuses: popupView.note }); //쪽지
              }}
            >
              <div className="left">
                <img id="choices_lefticon" src="/images/rocket.png" />
              </div>
              <div className="right">
                <p>
                  {/* 쪽지 */}
                  {this.i18nService.getMainMessage(this.state.lang, "message")}
                </p>
              </div>
            </a>
          </div>
        </div>

        {RenderPopup()}
        {RendePopup()}
      </>
    );
  }
}
