import React, { Component } from "react";
import Popup from "reactjs-popup";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";
import { I18nService } from "../service/i18n.service";

export enum noteView {
  none = "none",
  write = "write",
  view = "view",
}

interface Props {
  handleClose: () => any;
  handleDel: (id: string) => any;
  handleReadNote: (id: string) => any;
  note: any;
  lang: string;
}

interface State {
  mode: string;
  title: string;
  contents: string;
  id: string;
  lang: string;
}

export class Note extends Component<Props, State> {
  userService = new UserService();
  i18nService = new I18nService();

  constructor(props: Props) {
    super(props);

    this.state = {
      mode: noteView.view,
      title: "",
      contents: "",
      id: "",
      lang: this.props.lang,
    };
  }

  render() {
    const RenderView = () => {
      if (this.state.mode !== noteView.view) {
        return <></>;
      }
      return (
        <>
          <div className="modal-body">
            <table
              className="table table-bordered table-striped"
              style={{ marginBottom: "0px" }}
            >
              <thead>
                <tr>
                  <td style={{ width: "40%" }}>
                    {/* 제
                    <span style={{ letterSpacing: "0" }}>
                      목
                      
                    </span> */}
                    {this.i18nService.getBoardMessage(this.state.lang, "title")}
                  </td>
                  <td style={{ width: "20%" }}>
                    {/* 작성일 */}
                    {this.i18nService.getBoardMessage(this.state.lang, "date")}
                  </td>
                  <td style={{ width: "20%" }}>
                    {/* 상<span style={{ letterSpacing: "0" }}>태</span> */}
                    {this.i18nService.getBoardMessage(
                      this.state.lang,
                      "status"
                    )}
                  </td>
                  <td style={{ width: "20%" }}>
                    {/* 삭<span style={{ letterSpacing: "0" }}>제</span> */}
                    {this.i18nService.getBoardMessage(
                      this.state.lang,
                      "delete"
                    )}
                  </td>
                </tr>
              </thead>
              <tbody>
                {this.props.note.map((info: any) => (
                  <tr>
                    <td className="title text-truncate">
                      <a
                        href="javascript:;"
                        onClick={() => {
                          this.props.handleReadNote(info._id);
                          this.setState({
                            title: info.title,
                            contents: info.text,
                            mode: noteView.write,
                            id: info._id,
                          });
                        }}
                      >
                        {info.title}
                      </a>
                    </td>
                    <td className="date">{ConvertDate(info.regDate)}</td>
                    <td className="status" style={{ color: "#99FF00" }}>
                      {info.isRead === "y"
                        ? this.i18nService.getBoardMessage(
                            this.state.lang,
                            "check"
                          )
                        : this.i18nService.getBoardMessage(
                            this.state.lang,
                            "unidentified"
                          )}
                    </td>
                    <td className="article-deletion">
                      <button
                        type="button"
                        id="btn-cashout-proceed"
                        className="btn btn-theme-blue"
                        onClick={() => {
                          this.props.handleDel(info._id);
                        }}
                        style={{ flexBasis: "33.333%", marginLeft: "auto" }}
                      >
                        {/* 삭제 */}
                        {this.i18nService.getBoardMessage(
                          this.state.lang,
                          "delete"
                        )}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      );
    };

    const RenderWrite = () => {
      if (this.state.mode !== noteView.write) {
        return <></>;
      }
      return (
        <>
          <div className="modal-body">
            <table
              className="table table-bordered table-striped"
              style={{ marginBottom: "0px" }}
            >
              <thead>
                <tr>
                  <td
                    className="title text-truncate"
                    scope="col"
                    style={{ width: "80%" }}
                  >
                    {this.state.title}
                  </td>
                  <td className="date" scope="col" style={{ width: "20%" }}>
                    &nbsp;
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={2} className="article">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.contents,
                      }}
                    ></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              id="btn-cashout-proceed"
              className="btn bg-metallic"
              onClick={() => {
                this.setState({
                  title: "",
                  contents: "",
                  mode: noteView.view,
                  id: "",
                });
              }}
            >
              {/* 목록 */}
              {this.i18nService.getBoardMessage(this.state.lang, "list")}
            </button>
            <button
              type="button"
              id="btn-cashout-proceed"
              className="btn bg-metallic"
              style={{ marginLeft: "5px" }}
              onClick={() => {
                this.props.handleDel(this.state.id);
                this.setState({
                  title: "",
                  contents: "",
                  mode: noteView.view,
                  id: "",
                });
              }}
            >
              {/* 삭제 */}
              {this.i18nService.getBoardMessage(this.state.lang, "delete")}
            </button>
          </div>
        </>
      );
    };

    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              id="cashin-modal"
              className="modal modal-theme fade in"
              role="dialog"
              style={{ display: "block", paddingRight: "17px" }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">
                      {/* 쪽지 */}
                      {this.i18nService.getBoardMessage(
                        this.state.lang,
                        "message"
                      )}
                    </h4>
                    <button
                      className="btn-close"
                      data-dismiss="modal"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    ></button>
                  </div>
                  {RenderView()}
                  {RenderWrite()}
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
