import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { BalanceService } from "../service/balance.service";
import { SlotService } from "../service/slot.service";

import { ConverMoeny } from "../utility/help";
import { I18nService } from "../service/i18n.service";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
}

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
  updateUser: () => any;
  lang: string;
}

interface State {
  balance: string;
  lang: string;
}

export class Withdraw extends Component<Props, State> {
  balanceService = new BalanceService();
  i18nService = new I18nService();
  slotService = new SlotService();

  constructor(props: Props) {
    super(props);
    this.state = { balance: "", lang: this.props.lang };
  }

  
  componentDidMount() {
    this.handleUpdateInBalance();
  }

  handleUpdateInBalance = () => {
    this.slotService.get_in_game_balance().then((data: any) => {
      if (data.status === "success") {
  
        this.props.user.balance = data.balance ?? 0;
      } else {
      }
    });
  };


  handleDoWithdraw = () => {
    if (
      Number(this.state.balance === "" ? 0 : this.state.balance) % 10000 >
      0
    ) {
      confirmAlert({
        title: this.i18nService.getWithdrawMessage(this.state.lang, "withdraw"), //"출금",
        message: this.i18nService.getWithdrawMessage(
          this.state.lang,
          "message1"
        ), //"출금은 만원 단위로 가능합니다.",
        buttons: [
          {
            label: this.i18nService.getWithdrawMessage(
              this.state.lang,
              "confirm"
            ), //"확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }
    if (Number(this.state.balance === "" ? 0 : this.state.balance) < 10000) {
      confirmAlert({
        title: this.i18nService.getWithdrawMessage(this.state.lang, "withdraw"), //"출금",
        message: this.i18nService.getWithdrawMessage(
          this.state.lang,
          "message2"
        ), //"출금금액을 입력해주세요.",
        buttons: [
          {
            label: this.i18nService.getWithdrawMessage(
              this.state.lang,
              "confirm"
            ), //"확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.balanceService.applyUserWithdrawV2(this.state.balance).then((data) => {
      if (data.status === "success") {
        confirmAlert({
          title: "출금",
          message: "출금신청을 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else if (data.status === "balance") {
        confirmAlert({
          title: "출금",
          message: "보유머니가 부족하거나 게임에 1회 접속하셨는지 확인바랍니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else if (data.status === "wait") {
        confirmAlert({
          title: "출금",
          message: "대기중인 출금신청이 있습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "출금",
          message:
            "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };
  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              id="cashin-modal"
              className="modal modal-theme fade in"
              role="dialog"
              style={{ display: "block", paddingRight: "17px" }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">
                      {/* 출금신청 */}
                      {this.i18nService.getWithdrawMessage(
                        this.state.lang,
                        "withdraw"
                      )}
                    </h4>
                    <button
                      className="btn-close"
                      data-dismiss="modal"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <form
                      id="cashout-modal-form"
                      className="form-horizontal"
                      data-toggle="validator"
                      noValidate
                    >
                      <div className="modal-description">
                        <div>
                          <img
                            src="/images/tips.png"
                            className="img-responsive"
                          />
                          <p>
                            {/* 주의사항 */}
                            {this.i18nService.getWithdrawMessage(
                              this.state.lang,
                              "precautions"
                            )}
                          </p>
                        </div>
                        <div>
                          <p>
                            {/* 출금은 1만원 단위로만 신청 가능합니다. 출금신청시
                            입금 받으실 은행과 입금자 명을 꼭 확인하시기
                            바랍니다. */}
                            {this.i18nService.getWithdrawMessage(
                              this.state.lang,
                              "precautions1"
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>
                          {/* 보유금액 */}
                          {this.i18nService.getWithdrawMessage(
                            this.state.lang,
                            "holdamount"
                          )}
                        </label>
                        <p
                          className="text-yellow form-details"
                          id="current_balance"
                        >
                          {ConverMoeny(this.props.user.balance)} ₩
                        </p>
                      </div>
                      <div className="form-group">
                        <label>
                          {/* 입금자명 */}
                          {this.i18nService.getWithdrawMessage(
                            this.state.lang,
                            "depositorname"
                          )}
                        </label>
                        <input
                          className="form-control"
                          size={30}
                          value={this.props.user.bankowner}
                          readOnly
                          style={{ maxWidth: "40%" }}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          {/* 입금 은행명 */}
                          {this.i18nService.getWithdrawMessage(
                            this.state.lang,
                            "bankname"
                          )}
                        </label>
                        <input
                          className="form-control"
                          size={30}
                          value={this.props.user.bankname}
                          style={{ maxWidth: "40%" }}
                          readOnly
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          {/* 출금하실 금액 */}
                          {this.i18nService.getWithdrawMessage(
                            this.state.lang,
                            "amountwithdraw"
                          )}
                        </label>
                        <input
                          className="form-control"
                          size={30}
                          id="deposit_amount"
                          value={this.state.balance}
                          onClick={(e) =>
                            this.setState({
                              balance: "",
                            })
                          }
                          onChange={(e) =>
                            this.setState({
                              balance: e.target.value,
                            })
                          }
                        />
                        <span className="help-block"></span>
                      </div>
                    </form>

                    <div className="btngrp">
                      <button
                        id="cashin-input1"
                        className="btn btn-theme-default btn-sm"
                        onClick={() => {
                          this.setState({
                            balance: String(Number(this.state.balance) + 10000),
                          });
                        }}
                      >
                        {" "}
                        {/* 1만 */}
                        {this.i18nService.getWithdrawMessage(
                          this.state.lang,
                          "10k"
                        )}
                      </button>
                      <button
                        id="cashin-input2"
                        className="btn btn-theme-default btn-sm"
                        onClick={() => {
                          this.setState({
                            balance: String(Number(this.state.balance) + 50000),
                          });
                        }}
                        style={{ marginLeft: "5px" }}
                      >
                        {" "}
                        {/* 5만 */}
                        {this.i18nService.getWithdrawMessage(
                          this.state.lang,
                          "50k"
                        )}
                      </button>
                      <button
                        id="cashin-input3"
                        className="btn btn-theme-default btn-sm"
                        onClick={() => {
                          this.setState({
                            balance: String(
                              Number(this.state.balance) + 100000
                            ),
                          });
                        }}
                        style={{ marginLeft: "5px" }}
                      >
                        {" "}
                        {/* 10만 */}
                        {this.i18nService.getWithdrawMessage(
                          this.state.lang,
                          "100k"
                        )}
                      </button>
                      <button
                        id="cashin-input4"
                        className="btn btn-theme-default btn-sm"
                        onClick={() => {
                          this.setState({
                            balance: String(
                              Number(this.state.balance) + 500000
                            ),
                          });
                        }}
                        style={{ marginLeft: "5px" }}
                      >
                        {" "}
                        {/* 50만 */}
                        {this.i18nService.getWithdrawMessage(
                          this.state.lang,
                          "500k"
                        )}
                      </button>
                      <button
                        id="cashin-input5"
                        className="btn btn-theme-default btn-sm"
                        onClick={() => {
                          this.setState({
                            balance: String(
                              Number(this.state.balance) + 1000000
                            ),
                          });
                        }}
                        style={{ marginLeft: "5px" }}
                      >
                        {" "}
                        {/* 100만 */}
                        {this.i18nService.getWithdrawMessage(
                          this.state.lang,
                          "1000k"
                        )}
                      </button>
                      <button
                        id="cashin-input-clear"
                        className="btn btn-theme-dark-blue btn-sm"
                        onClick={() => {
                          this.setState({
                            balance: "",
                          });
                        }}
                        style={{ marginLeft: "5px" }}
                      >
                        {" "}
                        {/* 정정 */}
                        {this.i18nService.getWithdrawMessage(
                          this.state.lang,
                          "clear"
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      id="btn-cashout-proceed"
                      className="btn bg-metallic"
                      onClick={this.handleDoWithdraw}
                    >
                      {/* 신청하기 */}
                      {this.i18nService.getWithdrawMessage(
                        this.state.lang,
                        "apply"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
